import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css"; // Import ReactQuill styles
import ReactQuill from "react-quill"; // Import ReactQuill
import Sidebar from "./common/Sidebar";
import Navbar from "./common/Navbar";
import { ContentsState } from "../Context/ContentProvider";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  faExclamation,
  faLightbulb,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToggleSwitch from "./common/ToggleButton";

const ManageAbout = ({ selectedUser }) => {
  const [content, setContent] = useState(""); // State for ReactQuill content
  const [contactNumber, setContactNumber] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [logoImage, setLogoImage] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const { user } = ContentsState();
  const [aboutImage, setAboutImage] = useState(null); // Added state for about image
  const [mapURL, setMapURL] = useState("");
  const [image, setImage] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [logoImgTog, setLogoImgTog] = useState(false);
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    // const baseUrl = "http://localhost:5000";
  const [logoText, setLogoText] = useState("");
  const [aboutData, setAboutData] = useState(null);
  const { isDarkMode, toggleDarkMode } = ContentsState();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (selectedUser) {
      setCurrentUser(selectedUser);
    } else {
      setCurrentUser(user);
    }
  }, [selectedUser, user]);

  useEffect(() => {
    if (currentUser) {
      fetchData();
    }
  }, [currentUser]);

  const postDetails = (logoImages) => {
    if (!logoImages) {
      toast.error("No image selected");
      return;
    }
    if (logoImages.size > 1 * 1024 * 1024) {
      toast.error("Image size should be less than 1MB");
      return;
    }
    if (logoImages.type === "image/jpeg" || logoImages.type === "image/png") {
      const data = new FormData();
      data.append("file", logoImages);
      data.append("upload_preset", "Content-Management");
      data.append("cloud_name", "ddpbayems");
      data.append("folder", currentUser.url); // Dynamically setting folder name
      fetch("https://api.cloudinary.com/v1_1/ddpbayems/image/upload", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          setLogoImage(data.url.toString());
        })
        .catch((err) => {
          console.error(err);
          toast.error("Error uploading image");
        });
    } else {
      toast.error("Invalid file type. Only JPEG and PNG are allowed");
    }
  };

  const postAboutImage = (aboutImg) => {
    if (!aboutImg) {
      toast.error("No image selected");
      return;
    }
    if (aboutImg.size > 1 * 1024 * 1024) {
      toast.error("Image size should be less than 1MB");
      return;
    }
    if (aboutImg.type === "image/jpeg" || aboutImg.type === "image/png") {
      const data = new FormData();
      data.append("file", aboutImg);
      data.append("upload_preset", "Content-Management");
      data.append("cloud_name", "ddpbayems");
      data.append("folder", currentUser.url); // Dynamically setting folder name
      fetch("https://api.cloudinary.com/v1_1/ddpbayems/image/upload", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          setAboutImage(data.url.toString());
        })
        .catch((err) => {
          console.error(err);
          toast.error("Error uploading image");
        });
    } else {
      toast.error("Invalid file type. Only JPEG and PNG are allowed");
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/about`, {
        params: { url: currentUser.url },
      });
      const { data } = response;
      if (data) {
        setContent(data.aboutContent); // Set content to the data's aboutContent
        setContactNumber(data.contactNumber);
        setAddress(data.address);
        setEmail(data.email);
        setLogoImage(data.logoid); // Set the image URL
        setImage(data.logoid); // Display the fetched image
        setMapURL(data.mapURL); // Set the map URL
        setAboutImage(data.aboutImage);
        setLogoText(data.logoText);
        if (data.logoText) {
          setLogoImgTog(false);
        } else {
          setLogoImgTog(true);
        }
        setAboutData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleSubmit = async () => {
    // Validate required fields
    if (
      !content ||
      !contactNumber ||
      !address ||
      !email ||
      !mapURL ||
      (!logoText && !logoImage)
    ) {
      toast.error("Fill all the fields");
      return;
    }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };

      const postData = {
        currentContent: content,
        address: address,
        contactNumber: contactNumber,
        email: email,
        logoImage: logoImage, // Ensure logoImage is defined and assigned correctly
        user: currentUser, // Ensure user object contains necessary data
        mapURL: mapURL,
        aboutImage: aboutImage,
        logoText: logoText,
      };

      const response = await axios.post(
        `${baseUrl}/api/about/postAbout`,
        postData,
        config
      );
      fetchData();
      toast.success("Upload Success");
    } catch (err) {
      console.error(err);
      toast.error("Error submitting data");
    }
  };
  return (
    <div className={`flex h-screen bg-gray-400 w-full ${isDarkMode ? "dark-mode" : ""}`}>
      {!selectedUser ? (
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      ) : (
        <span></span>
      )}
      <div
        className={`flex-1 flex flex-col ${
          isOpen ? "ml-0" : ""
        } overflow-y-auto`}
      >
        {!selectedUser ? (
          <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen}  toggleDarkMode={toggleDarkMode} />
        ) : (
          <span></span>
        )}
        <div className="p-4">
          <h1
            className="text-2xl font-bold mb-4 uppercase text-gray-700"
            style={{ textAlign: "" }}
          >
            Manage About
          </h1>
          <ReactQuill
            value={content}
            onChange={handleContentChange}
            className="mb-6 my-quill-editor bg-slate-50" // Added margin bottom for spacing and custom class
            style={{ height: "200px" }}
          />

          <div className="flex flex-col md:flex-row mb-6 mt-16">
            <div className="w-full md:w-1/2 md:mr-4 mb-4 md:mb-0">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Contact Number:
                <input
                  type="text"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                  className="shadow appearance-none border rounded py-2 px-3 mt-1 focus:outline-none focus:shadow-outline w-full md:w-2/3 bg-slate-50"
                  placeholder="Enter contact number"
                />
              </label>
            </div>

            <div className="w-full md:w-1/2">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Email:
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="shadow appearance-none border rounded py-2 px-3 mt-1 focus:outline-none focus:shadow-outline w-full md:w-2/3 bg-slate-50"
                  placeholder="Enter email"
                />
              </label>
            </div>
          </div>

          <div className="flex flex-col md:flex-row mb-6">
            <div className="w-full md:w-1/2">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Address:
              </label>
              <div className="relative">
                <textarea
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="shadow appearance-none border rounded py-2 px-3 mt-1 focus:outline-none focus:shadow-outline md:w-2/3 h-24 resize-none bg-slate-50 text-gray-700"
                  placeholder="Enter address"
                />
              </div>
            </div>

            <div className="w-full md:w-1/2 md:mr-4 mb-4 md:mb-0">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Map URL:
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={mapURL}
                  onChange={(e) => setMapURL(e.target.value)}
                  className="shadow appearance-none border rounded py-2 px-3 mt-1 focus:outline-none focus:shadow-outline w-full md:w-2/3 bg-slate-50 text-gray-700"
                  placeholder="Enter Map URL"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row mb-6">
          <div className="w-full md:w-1/2 md:mr-4 mb-4 md:mb-0">
          <div className="flex items-center mb-4">
            <div className="flex items-center mr-4">
              <input
                type="radio"
                id="logoImage"
                name="logoOption"
                value="image"
                checked={logoImgTog}
                onChange={() => {
                  setLogoImgTog(true);
                  setLogoText(null);
                  if (aboutData.logoid) {
                    setLogoImage(aboutData.logoid);
                  }
                }}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
              />
              <label htmlFor="logoImage" className="ml-2 block text-gray-700 text-sm font-bold">
                Logo Image
              </label>
            </div>
            <div className="flex items-center ">
              <input
                type="radio"
                id="logoText"
                name="logoOption"
                value="text"
                checked={!logoImgTog}
                onChange={() => {
                  setLogoImgTog(false);
                  setLogoImage(null);
                  if (aboutData.logoText) {
                    setLogoText(aboutData.logoText);
                  }
                }}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 "
              />
              <label htmlFor="logoText" className="ml-2 block text-gray-700 text-sm font-bold">
                Logo Text
              </label>
            </div>
          </div>
          {logoImgTog ? (
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Logo Image:
              <input
                id="imageInput"
                type="file"
                accept="image/*"
                onChange={(e) => postDetails(e.target.files[0])}
                className="appearance-none border rounded py-2 px-3 mt-1 focus:outline-none focus:shadow-outline"
              />
              <p className="text-gray-400 mt-1 text-sm">
                <FontAwesomeIcon
                  icon={faLightbulb}
                  className="text-yellow-300 mr-1"
                />
                [Recommended size 500 × 500 and File size less than 1 MB]
              </p>
            </label>
          ) : (
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Logo Text:
              <input
                type="text"
                value={logoText}
                onChange={(e) => setLogoText(e.target.value)}
                className="shadow appearance-none border rounded py-2 px-3 mt-1 focus:outline-none focus:shadow-outline w-full md:w-2/3  bg-slate-50 text-gray-700"
                placeholder="Enter Logo Text"
              />
            </label>
          )}
          {logoImgTog && logoImage && (
            <div className="relative w-32 h-32 mt-2">
              <img
                src={logoImage}
                alt="Selected"
                className="w-full h-full object-cover rounded-lg"
              />
              <div className="absolute inset-0 flex items-center justify-center rounded-lg"></div>
            </div>
          )}
        </div>



            <div className="w-full md:w-1/2">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                About Image:
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => postAboutImage(e.target.files[0])}
                  className=" appearance-none border rounded py-2 px-3 mt-1 focus:outline-none focus:shadow-outline"
                />
                <p className="text-black-400">
                  <FontAwesomeIcon
                    icon={faLightbulb}
                    className="text-yellow-300"
                  />{" "}
                  [Recommended size 500 × 500 and File size less than 1 MB]
                </p>
              </label>
              {aboutImage && (
                <div className="relative w-32 h-32 mt-2">
                  <img
                    src={aboutImage}
                    alt="About"
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
              )}
            </div>
          </div>

          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-4 rounded"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ManageAbout;
