import React from 'react';

function Shimmer() {
  return (
    <div className="grid grid-cols-3 gap-4 p-4">
      {[...Array(6)].map((_, index) => (
        <div key={index} className="p-4 bg-white rounded-lg shadow-md animate-pulse">
          <div className="bg-gray-300 h-40 w-full rounded-md mb-4"></div>
          <div className="flex space-x-2 mt-2 items-center justify-between">
            <div className="bg-gray-300 h-8 w-12 rounded-md"></div>
            <div className="bg-gray-300 h-8 w-12 rounded-md"></div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Shimmer;
