import React, { useState, useEffect } from "react";
import Sidebar from "./common/Sidebar";
import Navbar from "./common/Navbar";
import ToggleButton from "./common/ToggleButton";
import { ContentsState } from "../Context/ContentProvider";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import ToggleSwitch from "./common/ToggleButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudUpload,
  faLightbulb,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

const ManageSlide = ({ selectedUser }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [slides, setSlides] = useState([]);
  const [fetchedSlides, setFetchedSlides] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const { user } = ContentsState();
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const { isDarkMode, toggleDarkMode } = ContentsState();

  const Swal = require("sweetalert2");
  // const useremail = "tarunyandra124@gmail.com";
  const [toggleStates, setToggleStates] = useState({});
  const [isActive, setIsActive] = useState(true);
  const [isUploading, setIsUploading] = useState(false); // State to track uploading status

  const handleToggle = (imageUrl) => {
    setToggleStates((prevStates) => ({
      ...prevStates,
      [imageUrl]: !prevStates[imageUrl],
    }));
  };
  const handleToggleStatus = (imageUrl, status) => {
    const statusReq = async () => {
      try {
        const response = await axios.put(`${baseUrl}/api/slides/changeStatus`, {
          params: {
            emailId: currentUser.email,
            imageUrl: imageUrl,
            status: status,
          },
        });
        fetchSlides();
        toast.success("Status Changed");
      } catch (error) {
        toast.error("Error deleting slide:", error.response.data);
      }
    };

    statusReq();
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (selectedUser) {
      setCurrentUser(selectedUser);
    } else {
      setCurrentUser(user);
    }
  }, [selectedUser, user]);

  useEffect(() => {
    if (currentUser) {
      fetchSlides();
    }
  }, [currentUser]);

  const fetchSlides = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/slides`, {
        params: { url: currentUser.url },
      });
      const { data } = response;
      if (data) {
        setFetchedSlides(data.images || []);
      }
    } catch (error) {
      console.error("Error fetching slides:", error);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    if (files.length + slides.length > 4) {
      toast.error("You cannot upload more than 4 images.");
      return;
    }
    setSelectedImages((prevImages) => [...prevImages, ...files]);
    files.forEach((file) => postDetails(file));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const postDetails = (file) => {
    if (!file || (file.type !== "image/jpeg" && file.type !== "image/png")) {
      toast.error("Invalid file type. Only JPEG and PNG are allowed.");
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      toast.error("Image size should be less than 2MB");
      return;
    }

    if (slides.length > 4) {
      toast.error("You cannot upload more than 4 images.");
      return;
    }

    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "Content-Management");
    data.append("cloud_name", "ddpbayems");
    data.append("folder", currentUser.url); // Dynamically setting folder name

    setIsUploading(true); // Set uploading status to true

    fetch("https://api.cloudinary.com/v1_1/ddpbayems/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        // Assuming data.url contains the URL of the uploaded image
        const imageUrl = data.url.toString();

        // Extracting publicId from imageUrl
        const publicId = imageUrl.split("/").slice(-2).join("/").split(".")[0];

        // Example of using publicId
        console.log("ID:", publicId);

        // Adding the uploaded image to slides state
        setSlides((prevSlides) => [
          ...prevSlides,
          { url: data.url.toString() },
        ]);
        return new Promise((resolve) => setTimeout(resolve, 2000))
      })
      .then(() => {
        setIsUploading(false); // Set uploading status to false after delay
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error uploading image");
        setIsUploading(false); // Set uploading status to false
      });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + slides.length > 4) {
      toast.error("You cannot upload more than 4 images.");
      return;
    }
    setSelectedImages((prevImages) => [...prevImages, ...files]);
    files.forEach((file) => postDetails(file));
  };

  const handleSubmit = () => {
    if (slides.length === 0) {
      toast.error("Select an Image");
      return;
    }

    const postData = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };
        const { data } = await axios.post(
          `${baseUrl}/api/slides/postSlides`,
          { user: currentUser, images: slides.map((slide) => slide.url) },
          config
        );
        toast.success("Upload Success");
        fetchSlides(); // Refresh the slides list after
        setSlides([]);
      } catch (err) {
        console.log(err);
        toast.error(err.response.data);
      }
    };

    postData();
  };

  const handleDelete = async (slideUrl) => {
    const deleteReq = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };
        const response = await axios.put(
          `${baseUrl}/api/slides/deleteSlide`,
          {
            params: {
              emailId: currentUser.email,
              imageUrl: slideUrl,
            },
          },
          config
        );
        if (response.status !== 200) {
          throw new Error("Failed to delete slide");
        }
        Swal.fire({
          title: "Deleted!",
          text: "Your Slide has been deleted.",
          icon: "success",
        });
        fetchSlides();
      } catch (error) {
        console.error("Error deleting slide:", error);
        toast.error("Error deleting slide");
      }
    };
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this slide?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReq();
      }
    });
  };

  return (
    <div className={`flex h-screen bg-gray-200 w-full ${isDarkMode ? "dark-mode" : ""}`}>
      {!selectedUser ? (
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      ) : (
        <span></span>
      )}
      <div
        className={`flex-1 flex flex-col ${
          isOpen ? "ml-0" : ""
        } overflow-y-auto`}
      >
        {!selectedUser ? (
          <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen}  toggleDarkMode={toggleDarkMode}  />
        ) : (
          <span></span>
        )}
        <div className="p-4">
          <h1
            className="text-2xl font-bold mb-4 uppercase text-gray-700 "
            style={{ textAlign: "" }}
          >
            Manage Slides
          </h1>
          <p className="text-gray-700 font-bold mb-2">
            <FontAwesomeIcon icon={faLightbulb} className="text-yellow-300" />{" "}
            [Recommended size 1920 × 1080 and File size less than 2 MB]
          </p>
          <div
            className="border-dashed border-2 border-gray-400 rounded-lg p-4 mb-4"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <label className="block text-gray-700 text-sm font-bold mb-2">
              <FontAwesomeIcon icon={faCloudUpload} className="mr-2" />
              Drag & Drop Images or Click to Upload
              <input
                type="file"
                accept="image/*"
                multiple
                className="hidden"
                onChange={handleImageChange}
              />
            </label>
            <div className="grid grid-cols-3 gap-4">
              {slides.map((slide, index) => (
                <div key={index}>
                  <img
                    src={slide.url}
                    alt={`${index}`}
                    className="rounded-lg"
                  />
                </div>
              ))}
            </div>
          </div>
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md  flex items-center justify-center"
            onClick={handleSubmit}
            disabled={isUploading}
          >
            {isUploading ? (
              <>
                <svg
                  className="animate-spin h-5 w-5 mr-3 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C6.477 0 2 4.477 2 10.5S6.477 21 12 21c1.285 0 2.505-.242 3.594-.674l-1.594-2.69A6.973 6.973 0 0112 18c-3.866 0-7-3.134-7-7z"
                  ></path>
                </svg>
                Uploading...
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
        <h2
          className="text-2xl font-bold mb-5 uppercase text-gray-700"
          style={{ textAlign: "center" }}
        >
          Slides
        </h2>
        <div className="grid grid-cols-3 gap-4 p-4">
          {fetchedSlides.map((image, index) => (
            <div key={index}>
              <img
                src={image.imageId}
                alt={`${index}`}
                className="rounded-lg"
                width="100%"
              />
              <div className="flex space-x-2 mt-2 items-center justify-between">
                <ToggleSwitch
                  isActive={image.status === "active" ? true : false}
                  onToggle={() =>
                    handleToggleStatus(
                      image.imageId,
                      image.status === "active" ? "inActive" : "active"
                    )
                  }
                  style={{ transform: "scale(0.55)" }}
                />
                <button
                  onClick={() => handleDelete(image.imageId)}
                  className="bg-red-500 text-white px-2 py-1 rounded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="size-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ManageSlide;
