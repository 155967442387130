import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ContentsState } from "../Context/ContentProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = ({ handleLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [phone, setPhone] = useState("");
  const [pic, setPic] = useState();
  const navigate = useNavigate();
  const { setUser } = ContentsState();
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;

  const postDetails = (pics) => {
    if (pics === undefined) {
      return;
    }
    if (pics.type === "image/jpeg" || pics.type === "image/png") {
      const data = new FormData();
      data.append("file", pics);
      data.append("upload_preset", "Content-Management");
      data.append("Content-Management", "dutrdc0yg");
      fetch("https://api.cloudinary.com/v1_1/dutrdc0yg/image/upload", {
        method: "post",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          setPic(data.url.toString());
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      return;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (isLogin) {
      if (!email || !password) {
        toast.error("Enter credentials");
        setLoading(false);
        return;
      }
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };

        const { data } = await axios.post(
          `${baseUrl}/api/user/login`,
          { email, password },
          config
        );
        setUser(data);
        localStorage.setItem("userInfo", JSON.stringify(data));
        setLoading(false);
        toast.success("Login successful");
        navigate("/cmspanel");
      } catch (err) {
        toast.error(err.response.data);
        setLoading(false);
      }
    } else {
      if (
        !name ||
        !email ||
        !password ||
        !confirmPassword ||
        !pic ||
        !companyName
      ) {
        toast.error("Please fill in all fields");
        setLoading(false);
        return;
      }
      if (password !== confirmPassword) {
        toast.error("Passwords do not match");
        setLoading(false);
        return;
      }
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
        const { data } = await axios.post(
          `${baseUrl}/api/user`,
          {
            name,
            email,
            password,
            pic,
            companyName,
            phone,
          },
          config
        );
        localStorage.setItem("userInfo", JSON.stringify(data));
        setUser(data);
        setLoading(false);
        setIsLogin(true);
        navigate("/cmspanel");
      } catch (err) {
        toast.error("Signup failed. Please try again.");
        setLoading(false);
      }
    }
  };

  const toggleAuth = () => {
    setIsLogin(!isLogin);
  };

  return (
   

    <div className="flex flex-col items-center justify-center h-screen bg-slate-300  md:w-full" >
      <div className="bg-white p-6 rounded-lg shadow-md w-96">
        <h2 className="text-2xl font-bold mb-4">
          {isLogin ? "Sign in to your account" : "Sign Up"}
        </h2>
        {!isLogin && (
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Name
            </label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 w-full rounded-md bg-gray-100 focus:outline-none focus:bg-white focus:ring focus:ring-blue-400 placeholder-gray-500 px-3 py-2"
              placeholder="Enter Your Name"
            />
            <label
              htmlFor="companyName"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Company Name
            </label>
            <input
              id="companyName"
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className="mt-1 w-full rounded-md bg-gray-100 focus:outline-none focus:bg-white focus:ring focus:ring-blue-400 placeholder-gray-500 px-3 py-2"
              placeholder="Enter Your Company Name"
            />
          </div>
        )}
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Email Address
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 w-full rounded-md bg-gray-100 focus:outline-none focus:bg-white focus:ring focus:ring-blue-400 placeholder-gray-500 px-3 py-2"
            placeholder="Enter Your Email Address"
          />
        </div>
        {!isLogin && (
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Phone
            </label>
            <input
              id="phone"
              type="text"
              value={phone}
              maxlength="10"
              pattern="\d{10}"
              title="Please enter exactly 10 digits"
              onChange={(e) => setPhone(e.target.value)}
              className="mt-1 w-full rounded-md bg-gray-100 focus:outline-none focus:bg-white focus:ring focus:ring-blue-400 placeholder-gray-500 px-3 py-2"
              placeholder="Enter your Mobile Number"
            />
          </div>
        )}
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Password
          </label>
          <div className="relative">
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 w-full rounded-md bg-gray-100 focus:outline-none focus:bg-white focus:ring focus:ring-blue-400 placeholder-gray-500 px-3 py-2"
              placeholder="Enter Password"
            />
            <button
              className="absolute inset-y-0 right-0 px-3 py-1 text-sm text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "Hide" : "Show"}
            </button>
          </div>
        </div>
        {!isLogin && (
          <div className="mb-4">
            <label
              htmlFor="confirm-password"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Confirm Password
            </label>
            <div className="relative">
              <input
                id="confirm-password"
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="mt-1 w-full rounded-md bg-gray-100 focus:outline-none focus:bg-white focus:ring focus:ring-blue-400 placeholder-gray-500 px-3 py-2"
                placeholder="Confirm Password"
              />
              <button
                className="absolute inset-y-0 right-0 px-3 py-1 text-sm text-gray-400 hover:text-gray-500 focus:outline-none"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
          </div>
        )}
        {!isLogin && (
          <div className="mb-4">
            <label
              htmlFor="pic"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Upload your Picture
            </label>
            <input
              id="pic"
              type="file"
              onChange={(e) => postDetails(e.target.files[0])}
              className="p-1.5 rounded-md bg-gray-100 focus:outline-none focus:bg-white focus:ring focus:ring-blue-400"
              accept="image/*"
            />
          </div>
        )}
         <button
        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md w-full flex items-center justify-center"
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? (
          <>
            <svg
              className="animate-spin h-5 w-5 mr-3 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C6.477 0 2 4.477 2 10.5S6.477 21 12 21c1.285 0 2.505-.242 3.594-.674l-1.594-2.69A6.973 6.973 0 0112 18c-3.866 0-7-3.134-7-7z"
              ></path>
            </svg>
            Processing...
          </>
        ) : (
          isLogin ? 'Login' : 'Sign Up'
        )}
      </button>
        {/* <Link
          to="/"
          className="block text-center mt-2 text-sm text-gray-700 hover:underline"
          onClick={toggleAuth}
        >
          {isLogin ? "Sign Up" : "Login"}
        </Link> */}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
