import React, { useState, useEffect, useRef } from "react";
import Sidebar from "./common/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Navbar from "./common/Navbar";
import { ContentsState } from "../Context/ContentProvider";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye , faQrcode,} from "@fortawesome/free-solid-svg-icons";
import QRCode from "qrcode.react";

function ManageUsers() {
  const [isOpen, setIsOpen] = useState(true);
  const { user } = ContentsState();
  const [users, setUsers] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [role, setRole] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [url, setUrl] = useState("");
  const [isUrlValid, setIsUrlValid] = useState(true);
  const [urlMessage, setUrlMessage] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { isDarkMode, toggleDarkMode } = ContentsState();

  



  const startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() + 1);
  const nextYearDate = startDate.toISOString().split("T")[0];
  const [expireDate, setExpireDate] = useState(nextYearDate);
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [totalPages, setTotalPages] = useState(0);
  const canvasRefs = useRef({});


  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const handleClear = () => {
    setName("");
    setEmail("");
    setPhone("");
    setStatus("inActive");
    setRole("user");
    setCompanyName("");
    setPassword("");
    setUrl("");
    setIsEditing(false);
    setExpireDate(nextYearDate);
  };
  const handleAddUser = () => {
    const registerUser = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
        const { data } = await axios.post(
          `${baseUrl}/api/user`,
          {
            name,
            email,
            password,
            companyName,
            phone,
            url,
          },
          config
        );
        setUsers([...users, data]);
        toast.success("User Created Successfully");
        handleClear();
      } catch (err) {
        console.log(err);
        toast.error(err.response.data.message);
      }
    };
    if (!name || !email || !password || !companyName) {
      toast.error("Please fill in all fields");
      return;
    }
    registerUser();
  };
  const fetchData = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };
      const response = await axios.get(
        `${baseUrl}/api/user/?page=${currentPage}`,
        config
      );
      const { data } = response;
      setUsers(data.users);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);
  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const handleUpdateUser = () => {
    const editReq = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };
        const response = await axios.put(
          `${baseUrl}/api/user/editUser`,
          {
            params: {
              name: name,
              emailId: email,
              phone: phone,
              status: status,
              role: role,
              companyName: companyName,
              password: password,
              url: url,
              expireDate: expireDate,
            },
          },
          config
        );

        fetchData();
        toast.success("User Updated Successfully");
        handleClear();
      } catch (err) {
        toast.error(err.response.data.message);
      }
    };
    editReq();
  };

  const checkUrlExists = async (currentUrl) => {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      console.log(currentUrl);
      const response = await axios.get(
        `${baseUrl}/api/user/checkurl`,
        { params: { url: currentUrl, email: email } },
        config
      );
      console.log(response);
      if (response.data.exists) {
        setIsUrlValid(false);
        setUrlMessage("URL already exists, please choose another name.");
      } else {
        setIsUrlValid(true);
        setUrlMessage("");
      }
    } catch (err) {
      console.error("Error checking URL:", err);
      setIsUrlValid(false);
      setUrlMessage("Error checking URL, please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (url) {
      const timer = setTimeout(() => {
        checkUrlExists(url);
      }, 500); // Delay the API call to avoid spamming the server

      return () => clearTimeout(timer); // Cleanup the timer on component unmount or url change
    }
  }, [url]);

  const handleUrlChange = (e) => {
    const inputUrl = e.target.value;
    const pattern = /^[A-Za-z0-9]*$/;
    if (pattern.test(inputUrl) || pattern === "") {
      setUrl(inputUrl);
    } else {
      alert("No special characters or spaces allowed");
    }
  };

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  if (user.role !== "admin") {
    return <h1>You are not authorized to access this page</h1>;
  }

  const downloadQRCode = (user) => {
    const canvas = canvasRefs.current[user.url]?.querySelector('canvas');
    if (canvas) {
      const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${user.url}-qrcode.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      alert("QR code not available");
    }
  };

  return (
    <div className={`flex h-screen w-full bg-gray-300 ${isDarkMode ? "dark-mode" : ""}`}>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />

      <div
        className={`flex-1 flex flex-col ${
          isOpen ? "ml-0" : ""
        } overflow-y-auto`}
      >
        <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen} toggleDarkMode={toggleDarkMode} />
        <div className="p-4">
          <h1
            className="text-2xl font-bold mb-4 uppercase text-gray-700"
            style={{ textAlign: "" }}
          >
            Manage Users
          </h1>
          {isFormVisible && (
            <div className="mx-auto  rounded-lg p-4 mb-4">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div>
                  <label
                    htmlFor="userName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    User Name
                  </label>
                  <input
                    type="text"
                    name="userName"
                    id="userName"
                    placeholder="User Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="border border-gray-300 p-2 rounded mt-1 w-full  bg-slate-50 text-gray-700"
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="border border-gray-300 p-2 rounded mt-1 w-full  bg-slate-50 text-gray-700"
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="border border-gray-300 p-2 rounded mt-1 w-full  bg-slate-50 text-gray-700"
                  />
                </div>
                <div>
                  <label
                    htmlFor="status"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Status
                  </label>
                  <select
                    name="status"
                    id="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className="border border-gray-300 p-2 rounded mt-1 w-full  bg-slate-50 text-gray-700"
                  >
                    <option value="active">Active</option>
                    <option value="inActive">InActive</option>
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Role
                  </label>
                  <select
                    name="role"
                    id="role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    className="border border-gray-300 p-2 rounded mt-1 w-full  bg-slate-50 text-gray-700"
                  >
                    <option value="user">User</option>
                    <option value="admin">Admin</option>
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="businessName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Business Name
                  </label>
                  <input
                    type="text"
                    name="businessName"
                    id="businessName"
                    placeholder="Business Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    className="border border-gray-300 p-2 rounded mt-1 w-full  bg-slate-50 text-gray-700"
                  />
                </div>
                <div>
                  <label
                    htmlFor="expiredate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Expire Date
                  </label>
                  <input
                    type="date"
                    name="expiredate"
                    id="expiredate"
                    placeholder="Expire Data"
                    value={expireDate}
                    onChange={(e) => setExpireDate(e.target.value)}
                    className="border border-gray-300 p-2 rounded mt-1 w-full  bg-slate-50 text-gray-700"
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="border border-gray-300 p-2 rounded mt-1 w-full  bg-slate-50 text-gray-700"
                  />
                </div>
                <div>
                  <label
                    htmlFor="url"
                    className="block text-sm font-medium text-gray-700"
                  >
                    URL
                  </label>
                  <div className="flex items-center">
                    <input
                      type="text"
                      name="url"
                      id="url"
                      placeholder="URL"
                      value={url}
                      onChange={handleUrlChange}
                      className={`border  bg-slate-50 text-gray-700 border-gray-300 p-2 rounded mt-1 w-full ${
                        isUrlValid ? "border-green-500" : "border-red-500"
                      }`}
                    />
                    <button
                      type="button"
                      className={`ml-2 p-2 rounded flex items-center ${
                        isUrlValid
                          ? "bg-green-500 text-white"
                          : "bg-red-500 text-white"
                      }`}
                    >
                      {isLoading ? (
                        <svg
                          className="animate-spin h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C6.477 0 2 4.477 2 10.5S6.477 21 12 21c1.285 0 2.505-.242 3.594-.674l-1.594-2.69A6.973 6.973 0 0112 18c-3.866 0-7-3.134-7-7z"
                          ></path>
                        </svg>
                      ) : (
                        <span>{isUrlValid ? "Available" : "Taken"}</span>
                      )}
                    </button>
                  </div>
                  {!isUrlValid && (
                    <p className="text-red-500 text-sm mt-1">{urlMessage}</p>
                  )}
                </div>
              </div>
              {isEditing ? (
                <button
                  onClick={handleUpdateUser}
                  className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
                >
                  Update User
                </button>
              ) : (
                <button
                  onClick={handleAddUser}
                  className="mt-4 bg-green-500 text-white py-2 px-4 rounded"
                >
                  Add User
                </button>
              )}
              <button
                onClick={handleClear}
                className="mt-4 mx-4 bg-yellow-400 text-white py-2 px-4 rounded"
              >
                Clear
              </button>
            </div>
          )}
          <div className="flex justify-center mt-2">
            <button
              onClick={toggleFormVisibility}
              className="text-gray-700 focus:outline-none"
            >
              <svg
                className={`w-6 h-6 transform transition-transform duration-300 ${
                  isFormVisible ? "rotate-180" : ""
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div>
          <table className="table-auto w-full  bg-white text-gray-700">
            <thead className="bg-slate-50">
              <tr>
                <th className="py-2 px-4 border-b text-left">Business Name</th>
                <th className="py-2 px-4 border-b text-left">Business Url</th>
                <th className="py-2 px-4 border-b text-left">Email</th>
                <th className="py-2 px-4 border-b text-left">Phone</th>
                <th className="py-2 px-4 border-b text-left">Status</th>
                <th className="py-2 px-4 border-b text-left">Role</th>
                <th className="py-2 px-4 border-b text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {users
                  .sort((a, b) => {
                    // Give admin users priority
                    if (a.role === 'admin' && b.role !== 'admin') return -1;
                    if (a.role !== 'admin' && b.role === 'admin') return 1;
                    return 0; // If both are admin or neither is admin, maintain current order
                  })
                  .map((user, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border-b  mx-auto text-left">
                    {user.companyName}
                  </td>
                  <td className="py-2 px-4 border-b  text-left mx-auto">
                    {user.url}
                  </td>
                  <td className="py-2 px-4 border-b   text-left mx-auto ">
                    {user.email}
                  </td>
                  <td className="py-2 px-4 border-b    text-left mx-auto">
                    {user.phone}
                  </td>
                  <td className="py-2 px-4 border-b   mx-auto text-left">
                    <span
                      className={
                        user.status === "active"
                          ? "bg-green-500 text-white px-4 py-1 rounded"
                          : "bg-red-500 text-white px-2 py-1 rounded"
                      }
                    >
                      {user.status}
                    </span>
                  </td>
                  <td className="py-2 px-4  border-b  text-left mx-auto">
                    {user.role}
                  </td>
                  <td className="py-2 px-4  border-b  text-left mx-auto">
                  <div className="flex justify-start items-center">
                    <button
                      onClick={() => {
                        setName(user.name);
                        setEmail(user.email);
                        setPhone(user.phone);
                        setStatus(user.status);
                        setRole(user.role);
                        setCompanyName(user.companyName);
                        setIsEditing(true);
                        setUrl(user.url);
                        // setPassword(user.password);
                        if (user.expireDate) {
                          setExpireDate(
                            moment(user.expireDate).format("YYYY-MM-DD")
                          );
                        } else {
                          setExpireDate(
                            moment(Date.now()).format("YYYY-MM-DD")
                          );
                        }
                      }}
                      className="  rounded text-yellow-500 hover:text-yellow-700 transition mr-2"
                    >
                      <FontAwesomeIcon icon={faEdit} size="md"  className="mx-0.5" />
                    </button>
                    <button
                       className="text-green-500 hover:text-blue-700 transition mr-2"
                      onClick={() => {
                        const url = `https://qrdcard.com/${user.url}`;
                        window.open(url, "_blank"); // Opens the URL in a new tab
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} size="md" className="mx-0.5" />
                    </button>
                    <div ref={(el) => (canvasRefs.current[user.url] = el)}>
                      <button
                      className="text-black-500 hover:text-blue-700 transition text-center m-auto"
                      onClick={() => downloadQRCode(user)}
                    >
                      <QRCode
                       value={`https://qrdcard.com/${user.url}`}
                        size={512}
                        level={"H"}
                        includeMargin={true}
                        renderAs="canvas"
                        className="text-center m-auto"
                        style={{ display: 'none' }}
                      />
                      <FontAwesomeIcon icon={faQrcode} size="lg" className="m-auto" /> 
                    </button>
                    </div>
                    </div>
                  </td>
        
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-center my-4">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className="mx-2 px-4 py-2 bg-red-500 text-white rounded disabled:opacity-50"
            >
              Previous
            </button>
            <span className="mx-2 px-4 py-2 text-gray-700">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="mx-2 px-4 py-2 bg-green-500 text-white rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ManageUsers;
