// src/components/ToggleSwitch.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { SunIcon, MoonIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

const ToggleSwitch = ({ isDarkMode, toggleDarkMode }) => {
    return (
        <div
            className={`w-14 h-8 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 ${isDarkMode ? 'bg-blue-500' : 'bg-gray-200'}`}
            onClick={toggleDarkMode}
        >
            <div
                className={`bg-white w-6 h-6 rounded-full shadow-md transform transition-transform duration-300 ease-in-out flex items-center justify-center ${isDarkMode ? 'translate-x-6' : ''}`}
            >
                {isDarkMode ? <FontAwesomeIcon icon={faMoon} size='xs' className="text-blue-400" /> : <SunIcon className="h-4 w-4 text-yellow-500" />}
            </div>
        </div>
    );
};

ToggleSwitch.propTypes = {
    isDarkMode: PropTypes.bool.isRequired,
    toggleDarkMode: PropTypes.func.isRequired,
};

export default ToggleSwitch;

