import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faClose, faEdit } from "@fortawesome/free-solid-svg-icons";
import { ContentsState } from "../../Context/ContentProvider";
import ManageAbout from "../ManageAbout";
import ManageSlide from "../ManageSlide";
import ManageServices from "../ManageServices";
import ManageGallery from "../ManageGallery";
import ManageBlog from "../ManageBlog";
import ManageNotification from "../ManageNotifications";
import ManageSEO from "../ManageSEO";
import ManageSocialMedia from "../ManageSocialmedia";
import ManageColorCode from "../ManageColorcode";

const AdminActions = ({ selectedUser }) => {
  const { user } = ContentsState();
  const [page, setPage] = useState(0);
  const choosenPage = () => {
    const arr = [
      "main",
      <ManageAbout selectedUser={selectedUser} />,
      <ManageSlide selectedUser={selectedUser} />,
      <ManageServices selectedUser={selectedUser} />,
      <ManageGallery selectedUser={selectedUser} />,
      <ManageBlog selectedUser={selectedUser} />,
      <ManageNotification selectedUser={selectedUser} />,
      <ManageSEO selectedUser={selectedUser} />,
      <ManageSocialMedia selectedUser={selectedUser} />,
      <ManageColorCode selectedUser={selectedUser} />,
    ];
    return arr[page];
  };

  if (user.role !== "admin") {
    return <h1>You are not authorized to access this page</h1>;
  }
  const handleBackToBusiness = () => {
    setPage(0);
  };
  return (
    <div className="flex  bg-gray-100 w-full">
      {page === 0 ? (
        <div className="flex-1 flex flex-col overflow-y-auto">
          <div className="p-6 bg-white shadow-md rounded-lg mx-4 my-6">
            <h1 className="text-3xl font-bold mb-6 text-gray-700 text-center uppercase">
              {selectedUser.companyName}
            </h1>
            <table className="table-auto w-full bg-white shadow-sm rounded-lg overflow-hidden  text-gray-700">
              <thead className="bg-gray-200">
                <tr>
                  <th className="py-3 px-6 text-center text-gray-700 font-bold">
                    Manage Options
                  </th>
                  <th className="py-3 px-6 text-center text-gray-700 font-bold">
                    Choose
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-3 px-6 border-b text-center">About</td>
                  <td className="py-3 px-6 border-b text-center">
                    <button
                      className="bg-yellow-500 text-white font-bold py-1 px-2 rounded transition duration-200 ease-in-out"
                      onClick={() => {
                        setPage(1);
                      }}
                    >
                      Manage <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="py-3 px-6 border-b text-center">Slides</td>
                  <td className="py-3 px-6 border-b text-center">
                    <button
                      className="bg-yellow-500 text-white font-bold py-1 px-2 rounded transition duration-200 ease-in-out"
                      onClick={() => {
                        setPage(2);
                      }}
                    >
                      Manage <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="py-3 px-6 border-b text-center">Services</td>
                  <td className="py-3 px-6 border-b text-center">
                    <button
                      className="bg-yellow-500 text-white font-bold py-1 px-2 rounded transition duration-200 ease-in-out"
                      onClick={() => {
                        setPage(3);
                      }}
                    >
                      Manage <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="py-3 px-6 border-b text-center">Gallery</td>
                  <td className="py-3 px-6 border-b text-center">
                    <button
                      className="bg-yellow-500 text-white font-bold py-1 px-2 rounded transition duration-200 ease-in-out"
                      onClick={() => {
                        setPage(4);
                      }}
                    >
                      Manage <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="py-3 px-6 border-b text-center">Blog</td>
                  <td className="py-3 px-6 border-b text-center">
                    <button
                      className="bg-yellow-500 text-white font-bold py-1 px-2 rounded transition duration-200 ease-in-out"
                      onClick={() => {
                        setPage(5);
                      }}
                    >
                      Manage <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="py-3 px-6 border-b text-center">
                    Notification
                  </td>
                  <td className="py-3 px-6 border-b text-center">
                    <button
                      className="bg-yellow-500 text-white font-bold py-1 px-2 rounded transition duration-200 ease-in-out"
                      onClick={() => {
                        setPage(6);
                      }}
                    >
                      Manage <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="py-3 px-6 border-b text-center">Meta Tags</td>
                  <td className="py-3 px-6 border-b text-center">
                    <button
                      className="bg-yellow-500 text-white font-bold py-1 px-2 rounded transition duration-200 ease-in-out"
                      onClick={() => {
                        setPage(7);
                      }}
                    >
                      Manage <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="py-3 px-6 border-b text-center">
                    Social Media
                  </td>
                  <td className="py-3 px-6 border-b text-center">
                    <button
                      className="bg-yellow-500 text-white font-bold py-1 px-2 rounded transition duration-200 ease-in-out"
                      onClick={() => {
                        setPage(8);
                      }}
                    >
                      Manage <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="py-3 px-6 border-b text-center">Color Code</td>
                  <td className="py-3 px-6 border-b text-center">
                    <button
                      className="bg-yellow-500 text-white font-bold py-1 px-2 rounded transition duration-200 ease-in-out"
                      onClick={() => {
                        setPage(9);
                      }}
                    >
                      Manage <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="p-4 mt-0 rounded w-full">
          <div className="flex justify-between items-center">
            <div className="flex-1 text-center">
              <h1 className="text-3xl font-bold mb-6 text-gray-700 text-center uppercase">
                {selectedUser.companyName}
              </h1>
            </div>
            <div className="flex-none">
              <button
                onClick={handleBackToBusiness}
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition duration-200 ease-in-out"
              >
                <FontAwesomeIcon icon={faClose} />
              </button>
            </div>
          </div>
          <div className="mt-4">{choosenPage()}</div>
        </div>
      )}
    </div>
  );
};
export default AdminActions;
