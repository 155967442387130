import React, { useState, useEffect } from "react";
import Sidebar from "./common/Sidebar";
import Navbar from "./common/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ContentsState } from "../Context/ContentProvider";
import axios from "axios";
import Swal from "sweetalert2";
import ToggleSwitch from "./common/ToggleButton";

function ManageNotification({ selectedUser }) {
  const [isOpen, setIsOpen] = useState(true);
  const [href, setHref] = useState("");
  const [title, setTitle] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [editNotificationId, setEditNotificationId] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const { user } = ContentsState();
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const { isDarkMode, toggleDarkMode } = ContentsState();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const handleToggleStatus = (index, status) => {
    const statusReq = async () => {
      try {
        const response = await axios.put(
          `${baseUrl}/api/notifications/changeStatus`,
          {
            params: {
              emailId: currentUser.email,
              index: index,
              status: status,
            },
          }
        );
        fetchData();
        toast.success("Status Changed");
      } catch (error) {
        console.error("Error changing status", error);
        toast.error("Error Changing Status");
      }
    };

    statusReq();
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/notifications/`,
        {
          params: { url: currentUser.url },
        }
      );
      const { data } = response;
      if (data) {
        setNotifications(data.notifications || []);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };

  useEffect(() => {
    if (selectedUser) {
      setCurrentUser(selectedUser);
    } else {
      setCurrentUser(user);
    }
  }, [selectedUser, user]);

  useEffect(() => {
    if (currentUser) {
      fetchData();
    }
  }, [currentUser]);
  const handleSubmit = async () => {
    if (!title || !href) {
      toast.error("Fill all the fields");
      return;
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const url =
        `${baseUrl}/api/notifications/postNotification`;
      const method = editNotificationId ? "post" : "post";

      await axios({
        method,
        url,
        data: {
          emailId: currentUser.email,
          notificationTitle: title,
          href: href,
          notificationId: editNotificationId,
        },
        headers: config.headers,
      });

      toast.success(`${editNotificationId ? "Update" : "Upload"} Success`);
      fetchData();
      setTitle("");
      setHref("");
      setEditNotificationId(null);
    } catch (err) {
      console.error(err);
      toast.error(`${editNotificationId ? "Update" : "Upload"} Failed`);
    }
  };

  const handleDelete = async (notificationId) => {
    const deleteReq = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        };
        await axios.put(
          `${baseUrl}/api/notifications/deleteNotification/`,
          { emailId: currentUser.email, notificationId: notificationId },
          config
        );
        Swal.fire({
          title: "Deleted!",
          text: "Your Notification has been deleted.",
          icon: "success",
        });
        fetchData();
      } catch (err) {
        console.error(err);
        toast.error("Delete Failed");
      }
    };

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this Notification",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReq();
      }
    });
  };

  const handleEdit = (notification) => {
    setTitle(notification.notificationTitle);
    setHref(notification.href);
    setEditNotificationId(notification._id);
  };

  const renderNotifications = () => {
    const notificationRows = [];
    for (let i = 0; i < notifications.length; i += 3) {
      const row = notifications.slice(i, i + 3);
      const rowContent = (
        <div key={i} className="flex flex-wrap -mx-4">
          {row.map((notification, index) => (
            <div key={i + index} className="w-full md:w-1/3 px-4 mb-4">
              <div className="bg-card p-4 rounded shadow">
                <h2 className="text-xl font-bold mb-2  text-gray-700">
                  {notification.notificationTitle}
                </h2>
                <p className=" text-gray-700">{notification.href}</p>
                <div className="flex space-x-2 justify-between">
                  <button
                    onClick={() => handleEdit(notification)}
                    className="bg-yellow-500 text-white p-2 rounded mt-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="size-5"
                    >
                      <path d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                      <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
                    </svg>
                  </button>
                  <ToggleSwitch
                    isActive={notification.status === "active" ? true : false}
                    onToggle={() =>
                      handleToggleStatus(
                        i + index,
                        notification.status === "active" ? "inActive" : "active"
                      )
                    }
                  />
                  <button
                    onClick={() => handleDelete(i + index)}
                    className="bg-red-500 text-white p-2 rounded mt-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="size-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
      notificationRows.push(rowContent);
    }
    return notificationRows;
  };

  return (
    <div className={`flex h-screen bg-gray-200 overflow-hidden ${isDarkMode ? "dark-mode" : ""}`}>
      {!selectedUser && (
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      )}
      <div
        className={`flex-1 flex flex-col ${
          isOpen ? "ml-0" : ""
        } overflow-y-auto`}
      >
        {!selectedUser && (
          <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen}  toggleDarkMode={toggleDarkMode}/>
        )}
        <div className="p-4">
          <h1
            className="text-2xl font-bold mb-4 uppercase  text-gray-700"
            style={{ textAlign: "" }}
          >
            Manage Notification
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Notification Title
              </label>
              <input
                type="text"
                placeholder="Notification Title"
                value={title}
                onChange={handleTitleChange}
                className="p-2 border border-gray-300 rounded w-full  bg-slate-50 text-gray-700"
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Notification Link
            </label>
            <input
              type="text"
              placeholder="Notification Link"
              value={href}
              onChange={(e) => setHref(e.target.value)}
              className="p-2 border border-gray-300 rounded w-full  bg-slate-50 text-gray-700"
            />
          </div>
          <div className="flex justify-center md:justify-start space-x-4">
            <button
              onClick={handleSubmit}
              className="bg-blue-500 text-white p-2 mt-9 rounded"
            >
              {editNotificationId
                ? "Update Notification"
                : "Upload Notification"}
            </button>
          </div>
          <h2
            className="text-2xl font-bold mb-5 uppercase  text-gray-700"
            style={{ textAlign: "center" }}
          >
            Notifications
          </h2>
          <div className="mt-4">{renderNotifications()}</div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ManageNotification;
