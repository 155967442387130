import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ContentsState } from "../../Context/ContentProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faInfoCircle,
  faImages,
  faBlog,
  faGears,
  faBell,
  faSignOutAlt,
  faChevronLeft,
  faChevronRight,
  faPalette,
  faThLarge,
  faBuilding,
  faCaretDown,
  faCaretUp,
  faUser,
  faFile,
  faShop,
  faFileExcel,
  faGlobe,
  faCar,
  faMagic,
  faGroupArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import { faMeta, faTwitter } from "@fortawesome/free-brands-svg-icons";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const { user } = ContentsState();
  const navigate = useNavigate();
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    // Check if current path is in the list of paths that should keep the dropdown open
    if (
      location.pathname === "/managecolorcode" ||
      location.pathname === "/managenotification" ||
      location.pathname === "/manageblog" ||
      location.pathname === "/managegallery" ||
      location.pathname === "/manageservices" ||
      location.pathname === "/manageslide" ||
      location.pathname === "/manageabout" ||
      location.pathname === "/managesocialmedia" ||
      location.pathname === "/manageseo"
    ) {
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  }, [location.pathname]);

  const logoutHandler = () => {
    localStorage.removeItem("userInfo");
    navigate("/");
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div
      className={`bg-gray-800 text-white h-screen ${
        isOpen ? "w-64" : "w-16"
      } transition-width duration-300`}
    >
      <div className="p-4 flex justify-between items-center">
        <h1
          className={`text-xl font-bold ${
            isOpen ? "block" : "hidden"
          } uppercase text-white-400`}
        >
          <FontAwesomeIcon icon={faGlobe} size='lg' className="text-blue-400 mr-2" /> {user.companyName}
        </h1>
        {/* <button
          className="text-gray-300 hover:text-white focus:outline-none"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon icon={isOpen ? faChevronLeft : faChevronRight} />
        </button> */}
      </div>
      <div className="scrollable-container">
      <ul className="mt-4">
        {/* Render admin specific links */}
        {user.role === "admin" && (
          <>
            {/* Render Manage Users link */}
            <li className="px-4  py-3 flex items-center">
              <NavLink
                to="/manageusers"
                className="flex items-center space-x-2"
              >
                <FontAwesomeIcon icon={faUsers} />
                <span
                  className={`font-bold text-white-500 ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  Manage Users
                </span>
              </NavLink>
            </li>
            <li className="px-4  py-3 flex items-center">
              <NavLink
                to="/manageimport"
                className="flex items-center space-x-2"
              >
                <FontAwesomeIcon icon={faFileExcel} />
                <span
                  className={`font-bold text-white-500 ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  Manage Import
                </span>
              </NavLink>
            </li>
            <li className="px-4  py-3 flex items-center">
              <NavLink to="/report" className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faFile} />
                <span
                  className={`font-bold text-white-500 ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  Get Reports
                </span>
              </NavLink>
            </li>
            <li className="px-4  py-3 flex items-center">
              <NavLink
                to="/managerestraunts"
                className="flex items-center space-x-2"
              >
                <FontAwesomeIcon icon={faShop} />
                <span
                  className={`font-bold text-white-500 ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  Manage Restraunts
                </span>
              </NavLink>
            </li>
            <li className="px-4  py-3 flex items-center">
              <NavLink
                to="/managewedding"
                className="flex items-center space-x-2"
              >
                <FontAwesomeIcon icon={faGroupArrowsRotate} />
                <span
                  className={`font-bold text-white-500 ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  Manage Wedding
                </span>
              </NavLink>
            </li>

            {/* Render Manage Sections link */}
            <li className="px-4  py-3 flex items-center">
              <NavLink
                to="/managesection"
                className="flex items-center space-x-2"
              >
                <FontAwesomeIcon icon={faThLarge} />
                <span
                  className={`font-bold text-white-500 ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  Manage Sections
                </span>
              </NavLink>
            </li>

            <li className="px-4  py-3 flex items-center">
              <NavLink
                to="/managebusiness"
                className="flex items-center space-x-2"
              >
                <FontAwesomeIcon icon={faBuilding} />
                <span
                  className={`font-bold text-white-500 ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  Manage Business
                </span>
              </NavLink>
            </li>

            {/* Render Manage Business dropdown */}
            <li className="px-4  py-3 flex flex-col items-start">
              <button
                onClick={toggleDropdown}
                className="flex items-center space-x-2 w-full text-left"
              >
                <FontAwesomeIcon icon={faUser} />
                <span
                  className={`font-bold text-white-500 ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  My Business
                </span>
                <FontAwesomeIcon
                  icon={isDropdownOpen ? faCaretUp : faCaretDown}
                />
              </button>
              {/* Render dropdown items */}
              {isDropdownOpen && (
                <ul className="pt-4 pl-4">
                  <li className="py-3">
                    <NavLink
                      to="/manageabout"
                      className="flex items-center space-x-2"
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                      <span
                        className={`font-bold text-white-500 ${
                          isOpen ? "block" : "hidden"
                        }`}
                      >
                        Manage About
                      </span>
                    </NavLink>
                  </li>
                  <li className="py-3">
                    <NavLink
                      to="/manageslide"
                      className="flex items-center space-x-2"
                    >
                      <FontAwesomeIcon icon={faImages} />
                      <span
                        className={`font-bold text-white-500 ${
                          isOpen ? "block" : "hidden"
                        }`}
                      >
                        Manage Slide
                      </span>
                    </NavLink>
                  </li>
                  <li className="py-3">
                    <NavLink
                      to="/manageseo"
                      className="flex items-center space-x-2"
                    >
                      <FontAwesomeIcon icon={faMeta} />
                      <span
                        className={`font-bold text-white-500 ${
                          isOpen ? "block" : "hidden"
                        }`}
                      >
                        Manage SEO
                      </span>
                    </NavLink>
                  </li>
                  <li className="py-3">
                    <NavLink
                      to="/manageservices"
                      className="flex items-center space-x-2"
                    >
                      <FontAwesomeIcon icon={faGears} />
                      <span
                        className={`font-bold text-white-500 ${
                          isOpen ? "block" : "hidden"
                        }`}
                      >
                        Manage Services
                      </span>
                    </NavLink>
                  </li>
                  <li className="py-3">
                    <NavLink
                      to="/managegallery"
                      className="flex items-center space-x-2"
                    >
                      <FontAwesomeIcon icon={faImages} />
                      <span
                        className={`font-bold text-white-500 ${
                          isOpen ? "block" : "hidden"
                        }`}
                      >
                        Manage Gallery
                      </span>
                    </NavLink>
                  </li>
                  <li className="py-3">
                    <NavLink
                      to="/manageblog"
                      className="flex items-center space-x-2"
                    >
                      <FontAwesomeIcon icon={faBlog} />
                      <span
                        className={`font-bold text-white-500 ${
                          isOpen ? "block" : "hidden"
                        }`}
                      >
                        Manage Blog
                      </span>
                    </NavLink>
                  </li>
                  <li className="py-3">
                    <NavLink
                      to="/managenotification"
                      className="flex items-center space-x-2"
                    >
                      <FontAwesomeIcon icon={faBell} />
                      <span
                        className={`font-bold text-white-500 ${
                          isOpen ? "block" : "hidden"
                        }`}
                      >
                        Manage Notification
                      </span>
                    </NavLink>
                  </li>
                  <li className="py-3">
                    <NavLink
                      to="/managecolorcode"
                      className="flex items-center space-x-2"
                    >
                      <FontAwesomeIcon icon={faPalette} />
                      <span
                        className={`font-bold text-white-500 ${
                          isOpen ? "block" : "hidden"
                        }`}
                      >
                        Manage Color Code
                      </span>
                    </NavLink>
                  </li>
                  <li className="py-3">
                    <NavLink
                      to="/managesocialmedia"
                      className="flex items-center space-x-2"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                      <span
                        className={`font-bold text-white-500 ${
                          isOpen ? "block" : "hidden"
                        }`}
                      >
                        Manage Social Media
                      </span>
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
          </>
        )}

        {/* Render user specific links */}
        {user.role === "user" && (
          <>
            {/* Render Manage About link */}
            <li className="p-4 flex items-center">
              <NavLink
                to="/manageabout"
                className="flex items-center space-x-2"
              >
                <FontAwesomeIcon icon={faInfoCircle} />
                <span
                  className={`font-bold text-white-500 ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  Manage About
                </span>
              </NavLink>
            </li>

            <li className="p-4 flex items-center">
              <NavLink to="/manageseo" className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faMeta} />
                <span
                  className={`font-bold text-white-500 ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  Manage SEO
                </span>
              </NavLink>
            </li>

            {/* Render Manage Slide link */}
            <li className="p-4 flex items-center">
              <NavLink
                to="/manageslide"
                className="flex items-center space-x-2"
              >
                <FontAwesomeIcon icon={faImages} />
                <span
                  className={`font-bold text-white-500 ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  Manage Slide
                </span>
              </NavLink>
            </li>

            {/* Render Manage Services link */}
            <li className="p-4 flex items-center">
              <NavLink
                to="/manageservices"
                className="flex items-center space-x-2"
              >
                <FontAwesomeIcon icon={faGears} />
                <span
                  className={`font-bold text-white-500 ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  Manage Services
                </span>
              </NavLink>
            </li>

            {/* Render Manage Gallery link */}
            <li className="p-4 flex items-center">
              <NavLink
                to="/managegallery"
                className="flex items-center space-x-2"
              >
                <FontAwesomeIcon icon={faImages} />
                <span
                  className={`font-bold text-white-500 ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  Manage Gallery
                </span>
              </NavLink>
            </li>

            {/* Render Manage Blog link */}
            <li className="p-4 flex items-center">
              <NavLink to="/manageblog" className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faBlog} />
                <span
                  className={`font-bold text-white-500 ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  Manage Blog
                </span>
              </NavLink>
            </li>

            {/* Render Manage Notification link */}
            <li className="p-4 flex items-center">
              <NavLink
                to="/managenotification"
                className="flex items-center space-x-2"
              >
                <FontAwesomeIcon icon={faBell} />
                <span
                  className={`font-bold text-white-500 ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  Manage Notification
                </span>
              </NavLink>
            </li>

            {/* Render Manage Color Code link */}
            <li className="p-4 flex items-center">
              <NavLink
                to="/managecolorcode"
                className="flex items-center space-x-2"
              >
                <FontAwesomeIcon icon={faPalette} />
                <span
                  className={`font-bold text-white-500 ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  Manage Color Code
                </span>
              </NavLink>
            </li>

            <li className="p-4 flex items-center">
              <NavLink
                to="/managesocialmedia"
                className="flex items-center space-x-2"
              >
                <FontAwesomeIcon icon={faTwitter} />
                <span
                  className={`font-bold text-white-500 ${
                    isOpen ? "block" : "hidden"
                  }`}
                >
                  Manage Social Media
                </span>
              </NavLink>
            </li>
          </>
        )}

        {/* Render logout link */}
        {/* <div className="absolute bottom-0">
          <ul>
            <li className="p-4 flex items-center">
              <NavLink
                to="/"
                className="flex items-center space-x-2 w-full py-2 text-red-500 text-center font-bold"
                onClick={logoutHandler}
              >
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                <span className={`font-bold text-white-500 ${isOpen ? "block" : "hidden"}`}>
                  Logout
                </span>
              </NavLink>
            </li>
          </ul>
        </div> */}
      </ul>
      </div>
    </div>
  );
};

export default Sidebar;
