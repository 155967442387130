import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ContentContext = createContext();

const ContentProvider = ({ children }) => {
  const [selectedContent, setSelectedContent] = useState();
  const [user, setUser] = useState();
  const [content, setContent] = useState();

    // Dark mode state
    const [isDarkMode, setIsDarkMode] = useState(() => {
      // Initialize from localStorage if available
      const savedMode = localStorage.getItem("isDarkMode");
      return savedMode === "true";
    });

  const navigate = useNavigate();

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    setUser(userInfo);
    ///
    if (!userInfo) navigate("/");
  }, [navigate]);

  useEffect(() => {
    // Store dark mode preference in localStorage
    localStorage.setItem("isDarkMode", isDarkMode);
  }, [isDarkMode]);

    // Toggle dark mode
    const toggleDarkMode = () => {
      setIsDarkMode(prevMode => !prevMode);
    };

  return (
    <ContentContext.Provider
      value={{
        selectedContent,
        setSelectedContent,
        user,
        setUser,
        content,
        setContent,
        isDarkMode,
        toggleDarkMode,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

export const ContentsState = () => {
  return useContext(ContentContext);
};

export default ContentProvider;



