import axios from "axios";
import moment from "moment-timezone";
import React, { useState } from "react";
import Sidebar from "./common/Sidebar";
import Navbar from "./common/Navbar";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ContentsState } from "../Context/ContentProvider";

const Report = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [users, setUsers] = useState([]);
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const { user } = ContentsState();
  const { isDarkMode, toggleDarkMode } = ContentsState();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${baseUrl}/api/user`, {
        params: { fromDate, toDate, status },
      });
      setUsers(response.data.users);
    } catch (err) {
      console.error("Error fetching users:", err.message);
    }
  };

  const handlePrint = () => {
    const doc = new jsPDF({
      orientation: "portrait",
    });

    const title = `User Status Report from ${moment(fromDate).format(
      "DD-MMMM-YYYY"
    )} to ${moment(toDate).format("DD-MMMM-YYYY")}`;

    doc.setFontSize(14);
    doc.text(title, 14, 15);

    const tableColumn = [
      "S.No",
      "Company Name",
      "Email",
      "Phone",
      "Status",
      "Created At",
      "Updated At",
      "Expire Date"
    ];
    const tableRows = [];

    users.forEach((user, index) => {
      const userData = [
        index + 1,
        user.companyName,
        user.email,
        user.phone,
        user.status,
        moment(user.createdAt).format("DD-MM-YYYY"),
        moment(user.updatedAt).format("DD-MM-YYYY"),
        moment(user.expireDate).format("DD-MM-YYYY")
      ];
      tableRows.push(userData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      styles: { fontSize: 8 },
      theme: "grid",
      startY: 25,
      margin: { top: 10 },
      columnStyles: {
        0: { cellWidth: "auto" },
        1: { cellWidth: "auto" },
        2: { cellWidth: "auto" },
        3: { cellWidth: "auto" },
        4: { cellWidth: "auto" },
        5: { cellWidth: "auto" },
        6: { cellWidth: "auto" },
      },
    });

    const today = moment().format("DDMMMYYYY");
    doc.save(`UserStatusReport_${today}.pdf`);
  };

  if (user.role !== "admin") {
    return <h1>You are not authorized to access this page</h1>;
  }


  return (
<div className={`flex h-screen bg-gray-200 w-full ${isDarkMode ? "dark-mode" : ""}`}>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div
        className={`flex-1 flex flex-col ${
          isOpen ? "ml-0" : ""
        } overflow-y-auto`}
      >
        <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen} toggleDarkMode={toggleDarkMode}  />
        <form
          onSubmit={handleSubmit}
          className="bg-gray-200 shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="fromDate"
                >
                  From Date
                </label>
                <input
                  type="date"
                  id="fromDate"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3  bg-slate-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="toDate"
                >
                  To Date
                </label>
                <input
                  type="date"
                  id="toDate"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3  bg-slate-50 text-gray-700  leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="w-full md:w-1/3 px-3">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="status"
                >
                  Status
                </label>
                <select
                  id="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3  bg-slate-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">Select Status</option>
                  <option value="active">Active</option>
                  <option value="inActive">In Active</option>
                </select>
              </div>
            </div>

          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={handlePrint}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Print
            </button>
          </div>
        </form>
        <div className="max-h-100 overflow-y-auto">
          {users.length > 0 && (
            <div className="overflow-x-auto">
              <table id="tableToPrint" className="table-auto w-full bg-white text-gray-700">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b">S.No</th>
                    <th className="py-2 px-4 border-b">Company Name</th>
                    <th className="py-2 px-4 border-b">Email</th>
                    <th className="py-2 px-4 border-b">Phone</th>
                    <th className="py-2 px-4 border-b">Status</th>
                    <th className="py-2 px-4 border-b">Created At</th>
                    <th className="py-2 px-4 border-b">Updated At</th>
                    <th className="py-2 px-4 border-b">Expire Date</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, index) => (
                    <tr key={user._id}>
                      <td className="py-2 px-4 border-b">{index + 1}</td>
                      <td className="py-2 px-4 border-b">{user.companyName}</td>
                      <td className="py-2 px-4 border-b">{user.email}</td>
                      <td className="py-2 px-4 border-b">{user.phone}</td>
                      <td className="py-2 px-4 border-b">{user.status}</td>
                      <td className="py-2 px-4 border-b">
                        {moment(user.createdAt).format("YYYY-MM-DD")}
                      </td>
                      <td className="py-2 px-4 border-b">
                        {moment(user.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td className="py-2 px-4 border-b">
                        {moment(user.expireDate).format("YYYY-MM-DD")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Report;
