import React, { useState, useEffect } from "react";
import Sidebar from "./common/Sidebar";
import Navbar from "./common/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ContentsState } from "../Context/ContentProvider";
import axios from "axios";
import Swal from "sweetalert2";
import ToggleSwitch from "./common/ToggleButton";
import { useAsyncError } from "react-router-dom";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const ManageSEO = ({ selectedUser }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [image, setImage] = useState("");
  const { user } = ContentsState();
  const [keywords, setKeywords] = useState("");
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const { isDarkMode, toggleDarkMode } = ContentsState();
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const postDetails = (Images) => {
    if (!Images) {
      toast.error("No image selected");
      return;
    }

    if (Images.type !== "image/jpeg" && Images.type !== "image/png") {
      toast.error("Invalid file type. Only JPEG and PNG are allowed.");
      return;
    }

    if (Images.size > 2 * 1024 * 1024) {
      toast.error("Image size should be less than 2MB");
      return;
    }

    const data = new FormData();
    data.append("file", Images);
    data.append("upload_preset", "Content-Management");
    data.append("cloud_name", "ddpbayems");
    data.append("folder", currentUser.url); // Dynamically setting folder name

    fetch("https://api.cloudinary.com/v1_1/ddpbayems/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        setImage(data.url.toString());
        // toast.success("Image uploaded successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error uploading image");
      });
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/seo`, {
        params: { url: currentUser.url },
      });
      const { data } = response;
      if (data) {
        setTitle(data.seoTitle);
        setDescription(data.seoDescription);
        setKeywords(data.keywords);
        setImage(data.metaImage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };

  useEffect(() => {
    if (selectedUser) {
      setCurrentUser(selectedUser);
    } else {
      setCurrentUser(user);
    }
  }, [selectedUser, user]);

  useEffect(() => {
    if (currentUser) {
      fetchData();
    }
  }, [currentUser]);
  const handleSubmit = async () => {
    if (!title || !description || !image || !keywords) {
      toast.error("Fill all the fields");
      return;
    }
    console.log(title);
    console.log(description);
    console.log(image);
    console.log(keywords);
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const url = `${baseUrl}/api/seo/postSeo`;
      const method = "post";

      await axios({
        method,
        url,
        data: {
          emailId: currentUser.email,
          seoTitle: title,
          seoDescription: description,
          keywords: keywords,
          metaImage: image,
          user: currentUser,
        },
        headers: config.headers,
      });

      toast.success(`Upload Success`);
      fetchData();
    } catch (err) {
      console.error(err);
      toast.error(`Upload Failed`);
    }
  };

  return (
    <div className={`flex h-screen bg-gray-200 w-full ${isDarkMode ? "dark-mode" : ""}`}>
    {!selectedUser && <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />}
    <div className={`flex-1 flex flex-col ${isOpen ? "ml-0" : ""} overflow-y-auto`}>
      {!selectedUser && <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen}  toggleDarkMode={toggleDarkMode}s />}
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4 uppercase text-gray-700" style={{ textAlign: "" }}>
          Manage SEO
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">SEO Title</label>
            <input
              type="text"
              placeholder="SEO Title"
              value={title}
              onChange={handleTitleChange}
              className="p-2 border border-gray-300 rounded w-full  bg-slate-50 text-gray-700"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="imageInput" className="text-gray-700 text-sm font-bold mb-2 cursor-pointer flex mt-10">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
                <path d="M9.25 13.25a.75.75 0 0 0 1.5 0V4.636l2.955 3.129a.75.75 0 0 0 1.09-1.03l-4.25-4.5a.75.75 0 0 0-1.09 0l-4.25 4.5a.75.75 0 1 0 1.09 1.03L9.25 4.636v8.614Z" />
                <path d="M3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5Z" />
              </svg>
              <span className="ml-1">Select Favicon</span>
            </label>
            <input id="imageInput" type="file" accept="image/*" onChange={(e) => postDetails(e.target.files[0])} className="hidden" />
            {image && (
              <div className="relative w-32 h-32 mb-4">
                <img src={image} alt="Selected" className="w-full h-full object-cover rounded-lg" />
                <div className="absolute inset-0 flex items-center justify-center rounded-lg"></div>
              </div>
            )}
            <p className="text-gray-700 font-bold">
              <FontAwesomeIcon icon={faLightbulb} className="text-yellow-300" /> [Recommended size 1080 × 1080 and File size less than 1 MB]
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row mb-6">
          <div className="w-full md:w-1/2 md:mr-4 mb-4 md:mb-0">
            <label className="block text-gray-700 text-sm font-bold mb-2">Description</label>
            <textarea
              type="text"
              placeholder="SEO Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="shadow appearance-none border rounded py-2 px-3 mt-1 focus:outline-none focus:shadow-outline md:w-full h-24 resize-none  bg-slate-50 text-gray-700"
            />
          </div>
          <div className="w-full md:w-1/2">
            <label className="block text-gray-700 text-sm font-bold mb-2">Keywords</label>
            <textarea
              type="text"
              placeholder="SEO Keywords"
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
              className="shadow appearance-none border rounded py-2 px-3 mt-1 focus:outline-none focus:shadow-outline md:w-full h-24 resize-none  bg-slate-50 text-gray-700"
            />
          </div>
          
        </div>
        <div className="flex justify-center md:justify-start space-x-4">
          <button onClick={handleSubmit} className="bg-blue-500 text-white p-2 mt-9 rounded">
            Upload MetaData
          </button>
        </div>
      </div>
    </div>
    <ToastContainer />
  </div>
  
  );
};

export default ManageSEO;
