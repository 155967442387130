import React from "react";

const ToggleSwitch = ({ isActive, onToggle }) => (
  <div
    className={`flex items-center cursor-pointer rounded-full p-1 w-14 h-8 ${
      isActive ? "bg-green-500" : "bg-gray-300"
    }`}
    onClick={onToggle}
  >
    <div
      className={`bg-white w-6 h-6 rounded-full shadow-md transform transition-transform ${
        isActive ? "translate-x-6" : ""
      }`}
    ></div>
  </div>
);

export default ToggleSwitch;
