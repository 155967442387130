import React, { useState } from "react";

import CMSPanel from "./Pages/CMSpanel";
import LoginPage from "./Pages/Login";
import ManageAbout from "./components/ManageAbout";
import ManageSlide from "./components/ManageSlide";
import { Route, Routes } from "react-router-dom";
import { ContentsState } from "./Context/ContentProvider";
import ManageBlog from "./components/ManageBlog";
import ManageGallery from "./components/ManageGallery";
import ManageServices from "./components/ManageServices";
import ManageNotification from "./components/ManageNotifications";
import ManageUsers from "./components/ManageUsers";
import ManageColorcode from "./components/ManageColorcode";
import ManageSection from "./components/ManageSection";
import ManageBusiness from "./components/manageBusiness";
import ManagePassword from "./components/ManagePassword";
import ManageSocialmedia from "./components/ManageSocialmedia";
import Report from "./components/Report";
import ManageSEO from "./components/ManageSEO";
import ManageImport from "./components/ManageImport";
import ManageRestaurants from "./components/ManageRestraunt";
import ManageRestraunts from "./components/ManageRestraunt";
import Menu from "./components/common/Menu";
import ManageWedding from "./components/ManageWedding";
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { user } = ContentsState();
  // Function to handle successful login
  const handleLogin = () => {
    // Perform login logic here
    setIsLoggedIn(!isLoggedIn);
  };

  return (
    <Routes>
      <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
      {/* Always render CMSPanel route */}
      <Route path="/cmspanel" element={<CMSPanel />} />
      {/* Redirect to CMSPanel if logged in, otherwise to LoginPage */}
      <Route
        path="/"
        element={
          user ? (
            <CMSPanel onLogin={handleLogin} />
          ) : (
            <LoginPage onLogin={handleLogin} />
          )
        }
      />

      <Route
        path="/manageabout"
        element={
          user ? <ManageAbout onLogin={handleLogin} /> : <h1>Loading </h1>
        }
      />

      <Route
        path="/manageslide"
        element={
          user ? <ManageSlide onLogin={handleLogin} /> : <h1>Loading</h1>
        }
      />
      <Route
        path="/managegallery"
        element={
          user ? <ManageGallery onLogin={handleLogin} /> : <h1>Loading</h1>
        }
      />
      <Route
        path="/manageservices"
        element={
          user ? <ManageServices onLogin={handleLogin} /> : <h1>Loading</h1>
        }
      />
      <Route
        path="/manageblog"
        element={user ? <ManageBlog onLogin={handleLogin} /> : <h1>Loading</h1>}
      />
      <Route
        path="/managenotification"
        element={
          user ? <ManageNotification onLogin={handleLogin} /> : <h1>Loading</h1>
        }
      />
      <Route
        path="/manageseo"
        element={user ? <ManageSEO onLogin={handleLogin} /> : <h1>Loading</h1>}
      />
      <Route
        path="/manageusers"
        element={
          user ? <ManageUsers onLogin={handleLogin} /> : <h1>Loading</h1>
        }
      />
      <Route
        path="/managecolorcode"
        element={
          user ? <ManageColorcode onLogin={handleLogin} /> : <h1>Loading</h1>
        }
      />
      <Route
        path="/managesection"
        element={
          user ? <ManageSection onLogin={handleLogin} /> : <h1>Loading </h1>
        }
      />
      <Route
        path="/managebusiness"
        element={
          user ? <ManageBusiness onLogin={handleLogin} /> : <h1>Loading </h1>
        }
      />
      <Route
        path="/managerestraunts"
        element={
          user ? <ManageRestraunts onLogin={handleLogin} /> : <h1>Loading </h1>
        }
      />
      <Route
        path="/manageimport"
        element={
          user ? <ManageImport onLogin={handleLogin} /> : <h1>Loading </h1>
        }
      />
      <Route path="/report" element={user ? <Report /> : <h1>Loading </h1>} />
      <Route
        path="/managepassword"
        element={
          user ? <ManagePassword onLogin={handleLogin} /> : <h1>Loading </h1>
        }
      />
      <Route
        path="/managesocialmedia"
        element={
          user ? <ManageSocialmedia onLogin={handleLogin} /> : <h1>Loading </h1>
        }
      />
      <Route
        path="/menu/:guid"
        element={
          user ? <Menu /> : <h1 className="text-center mt-10">Loading...</h1>
        }
      />
      <Route
        path="/managewedding"
        element={
          user ? (
            <ManageWedding />
          ) : (
            <h1 className="text-center mt-10">Loading...</h1>
          )
        }
      />
    </Routes>
  );
}

export default App;
