import React, { useEffect, useState , useRef } from "react";
import QRCode from "qrcode.react";
import Sidebar from "./common/Sidebar";
import Navbar from "./common/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContentsState } from "../Context/ContentProvider";
import {
  faArrowLeft,
  faArrowRight,
  faChevronCircleLeft,
  faChevronCircleRight,
  faCircleLeft,
  faCirclePlus,
  faCircleRight,
  faEdit,
  faEye,
  faQrcode,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Swal from "sweetalert2";

const initialShops = [
];

function ManageRestraunts() {
  const [isOpen, setIsOpen] = useState(true);
  const [shops, setShops] = useState(initialShops);
  const [currentShop, setCurrentShop] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const { user } = ContentsState();
  const [isEdit, setIsEdit] = useState(false);
  const { isDarkMode, toggleDarkMode } = ContentsState();
  const canvasRefs = useRef({});
  const [totalShops, setTotalShops] = useState(shops.length);// New state for the number of shops

    // Initialize expiration date to one year from the current date
  const startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() + 1);
  const nextYearDate = startDate.toISOString().split("T")[0];

  const [isUploading, setIsUploading] = useState(false); // State to track uploading status


  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


  useEffect(() => {
    setTotalShops(shops.length);
  }, [shops]);




  
  // const openModal = (shop) => {
  //   setCurrentShop(shop);
  //   setModalOpen(true);
  // };

  const openModal = (shop = {}) => {
    const newShop = {
      ...shop,
      expireDate: shop.expireDate || nextYearDate,
    };
    
    setCurrentShop(newShop);
    setModalOpen(true);
  };

  const closeModal = () => {
    if (isEdit) {
      setIsEdit(false);
    }
    setCurrentShop(null);
    setModalOpen(false);
  };

  const saveShop = (shop) => {
    if (shop.id) {
      setShops(shops.map((s) => (s.id === shop.id ? shop : s)));
    } else {
      shop.id = shops.length + 1;
      setShops([...shops, shop]);
    }
    closeModal();
  };

  const handleChange = (e) => {
    // console.log(currentShop);
    const { name, value, files } = e.target;

    if (name === "imageUrls") {
      const validFileExtensions = ["-", "_", "."];
      const isValidFileName = (filename) => {
        return true;
      };

      const urls = Array.from(files)
        .filter((file) => isValidFileName(file.name))
        .map((file) => postDetails(file));

      // Optionally, you can handle invalid files here if needed
      const invalidFiles = Array.from(files).filter(
        (file) => !isValidFileName(file.name)
      );

      if (invalidFiles.length > 0) {
        toast.error("Invalid File Name not processed");
      }
    } else {
      setCurrentShop({
        ...currentShop,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    const registerRest = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };

        const { data } = axios.post(
          `${baseUrl}/api/restraunt/`,
          {
            clientName: currentShop.clientName,
            businessName: currentShop.businessName,
            mobileNumber: currentShop.mobileNumber,
            imageUrls: currentShop.imageUrls,
            status: currentShop.status,
            expireDate: currentShop.expireDate,
          },
          config
        );
        if (data) toast.success("Data Submitted Successfully");
        setModalOpen(false);
        fetchData();
       
        
      } catch (err) {
        console.error("Error Uploading Restraunt", err);
        toast.error("Error Occured");
      }
    };
    const editRest = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };

        const { data } = await axios.put(
          `${baseUrl}/api/restraunt/${currentShop.guid}`,
          {
            clientName: currentShop.clientName,
            businessName: currentShop.businessName,
            mobileNumber: currentShop.mobileNumber,
            imageUrls: currentShop.imageUrls,
            status: currentShop.status,
            expireDate: currentShop.expireDate,
          },
          config
        );

        if (data) toast.success("Data Updated Successfully");
        setModalOpen(false);
        fetchData();
       
      } catch (err) {
        console.error("Error Updating Restaurant", err);
        toast.error("Error Occurred");
      }
    };
    // console.log(currentShop);
    if (
      !currentShop.clientName ||
      !currentShop.businessName ||
      !currentShop.mobileNumber ||
      !currentShop.imageUrls ||
      !currentShop.expireDate
    ) {
      toast.error("Enter all the fields");
      return;
    }

    if (currentShop.guid) {
      editRest();
    } else {
      registerRest();
    }
  };
  useEffect(() => {
    fetchData();
  }, [isModalOpen]);

  const fetchData = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };
      const response = await axios.get(`${baseUrl}/api/restraunt?page=${currentPage}`, config);
      const { data } = response;
      setShops(data.restraunts);
       setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };


  
  useEffect(() => {
    fetchData();
  }, [currentPage]);
  useEffect(() => {
    fetchData();
  }, []);

  const postDetails = async (file) => {
    if (!file || (file.type !== "image/jpeg" && file.type !== "image/png")) {
      toast.error("Invalid file type. Only JPEG and PNG are allowed.");
      return;
    }
    if (!currentShop.businessName) {
      toast.error("Enter the business Name before uploading the image");
      return;
    }
    if (file.size > 2 * 1024 * 1024) {
      toast.error("Image size should be less than 2MB");
      return;
    }

    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "Content-Management");
    data.append("cloud_name", "ddpbayems");
    data.append("folder", currentShop.businessName.replace(/ /g, "-")); // Dynamically setting folder name
    setIsUploading(true); // Set uploading status to true

    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/ddpbayems/image/upload",
        {
          method: "POST",
          body: data,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      const responseData = await response.json();
      const imageUrl = responseData.url;
      const publicId = imageUrl.split("/").slice(-2).join("/").split(".")[0];

      // console.log(responseData);

      setCurrentShop((prevShop) => ({
        ...prevShop,
        imageUrls: prevShop.imageUrls
          ? [...prevShop.imageUrls, imageUrl]
          : [imageUrl],
      }));
      
      // 2-second delay before setting isUploading to false
      setTimeout(() => {
        setIsUploading(false);
      }, 500);
    } catch (err) {
      console.log(err);
      toast.error("Error uploading image");
      setIsUploading(false); // Set uploading status to false in case of error
    }
  };
  const handleDelete = (shop) => {
    const deleteReq = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };

        const { data } = await axios.delete(
          `${baseUrl}/api/restraunt/${shop.guid}`,
          config
        );

        if (data.success) {
          toast.success("Restaurant deleted successfully");
          fetchData();
        }
        // console.log(data);
      } catch (err) {
        console.error("Error Deleting Restaurant", err);
        toast.error("Error Occurred");
      }
    };
    if (shop.imageUrls.length > 0) {
      toast.error("Delete the images of the user first");
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this Restraunt",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReq();
      }
    });
  };

  const handleImageDelete = (imageUrl) => {
    const imageDeleteReq = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };

        const { data } = await axios.delete(
          `${baseUrl}/api/restraunt/${currentShop.guid}/images`,
          {
            ...config,
            data: {
              guid: currentShop.guid,
              imageUrl: imageUrl,
            },
          }
        );

        if (data.success) {
          toast.success("Image deleted successfully");
          // Update local state to remove the deleted image URL
          fetchData();
          setCurrentShop((prevShop) => ({
            ...prevShop,
            imageUrls: prevShop.imageUrls.filter((url) => url !== imageUrl),
          }));
        }
        // console.log(data);
      } catch (err) {
        console.error("Error Deleting Image", err);
        toast.error("Error Occurred");
      }
    };
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this Image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        imageDeleteReq();
      }
    });
  };
  const downloadQRCode = (shop) => {
    const canvas = canvasRefs.current[shop.guid]?.querySelector('canvas');
    if (canvas) {
      const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${shop.guid}-qrcode.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      alert("QR code not available");
    }
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  if (user.role !== "admin") {
    return <h1>You are not authorized to access this page</h1>;
  }


  return (
    <div className={`flex h-screen bg-gray-200 w-full ${isDarkMode ? "dark-mode" : ""}`}>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div
        className={`flex-1 flex flex-col ${
          isOpen ? "ml-0" : ""
        } overflow-y-auto`}
      >
        <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen} toggleDarkMode={toggleDarkMode}  />
        <div className="container mx-auto p-4">
          <div className="flex justify-end mb-4">
            <button
              className="bg-gray-800 text-white px-6 py-2 rounded-md flex items-center hover:bg-blue-600 transition"
              onClick={() => openModal({})}
            >
              <FontAwesomeIcon icon={faCirclePlus} size="sm" className="mx-2"/>  Shop
            </button>
          </div>
          <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="p-4">Client Name</th>
                <th className="p-4">Business Name</th>
                <th className="p-4">Mobile Number</th>
                <th className="p-4">Image Uploads</th>
                <th className="p-4">Status</th>
                <th className="p-4">Created At</th>
                <th className="p-4">Expires At</th>
                <th className="p-4">Download QR</th>
                <th className="p-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {shops.map((shop) => (
                <tr key={shop.id} className="border-t border-gray-200 text-gray-700">
                  <td className="p-4">{shop.clientName}</td>
                  <td className="p-4">{shop.businessName}</td>
                  <td className="p-4">{shop.mobileNumber}</td>
                  <td
                    className="p-4 text-center"
                    style={{ justifyContent: "center" }}
                  >
                    {Array.isArray(shop.imageUrls) &&
                      `${shop.imageUrls.length}`}
                  </td>

                  <td className="p-4">
                    <span
                      className={`px-2 py-1 rounded-full ${
                        shop.status === "active"
                          ? "bg-green-200 text-green-800"
                          : "bg-red-200 text-red-800"
                      }`}
                    >
                      {shop.status}
                    </span>
                  </td>
                  <td className="p-4">
                    {moment(shop.createdAt).format("DD/MM/YYYY")}
                  </td>
                  <td className="p-4">
                    {moment(shop.expireDate).format("DD/MM/YYYY")}
                  </td>
                  <td className="p-4" style={{ justifyContent: "center" }}>
                    <div ref={(el) => (canvasRefs.current[shop.guid] = el)}>
                      <button
                      className="text-black-500 hover:text-blue-700 transition text-center m-auto"
                      onClick={() => downloadQRCode(shop)}
                    >
                      <QRCode
                       value={`https://qrdcard.com/menu/${shop.guid}`}
                        size={512}
                        level={"H"}
                        includeMargin={true}
                        renderAs="canvas"
                        className="text-center m-auto"
                        style={{ display: 'none' }}
                      />
                      <FontAwesomeIcon icon={faQrcode} size="lg" className="m-auto" /> 
                    </button>
                    </div>
                    
                  </td>
                  <td className="p-4">
                    <button
                       className="text-gray-700 hover:text-blue-700 transition mr-2"
                      onClick={() => {
                        const url = `${window.location.origin}/menu/${shop.guid}`;
                        window.open(url, "_blank"); // Opens the URL in a new tab
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} size="md" className="mx-1" />
                    </button>
                    <button
                      className="text-yellow-500 hover:text-yellow-700 transition mr-2"
                      onClick={() => {
                        setIsEdit(true);
                        openModal(shop);
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} size="md"  className="mx-1" />
                    </button>
                    <button
                      className="text-red-500 hover:text-red-700 transition"
                      onClick={() => {
                        handleDelete(shop);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} size="md"  className="mx-1"/>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 p-4">
              <div className="bg-white px-6 py-4 rounded-md shadow-md w-1/3 mt-14 mb-14">
                <h2 className="text-2xl font-bold mb-2 text-gray-700">
                  {currentShop.id ? "Edit Shop" : "Add Shop"}
                </h2>
                <div>
                  <div className="mb-3">
                    <label className="block text-gray-700">Client Name</label>
                    <input
                      type="text"
                      name="clientName"
                      value={currentShop.clientName || ""}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md  bg-slate-50 text-gray-700"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block text-gray-700">Business Name</label>
                    <input
                      type="text"
                      name="businessName"
                      value={currentShop.businessName || ""}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md  bg-slate-50 text-gray-700"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block text-gray-700">Mobile Number</label>
                    <input
                      type="text"
                      name="mobileNumber"
                      value={currentShop.mobileNumber || ""}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md  bg-slate-50 text-gray-700"
                    />
                  </div>
                  <div className="mb-3">
                <label className="block text-gray-700">Image Uploads</label>
                <input
                  type="file"
                  name="imageUrls"
                  multiple
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-md bg-slate-50 text-gray-700"
                />
              </div>

              {isUploading ? (
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-gray-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C6.477 0 2 4.477 2 10.5S6.477 21 12 21c1.285 0 2.505-.242 3.594-.674l-1.594-2.69A6.973 6.973 0 0112 18c-3.866 0-7-3.134-7-7z"
                    ></path>
                  </svg>
                </div>
              ) : (
                currentShop.imageUrls && (
                  <div className="image-list-container text-gray-700">
                    <ul>
                      {currentShop.imageUrls.map((image, index) => (
                        <li key={index}>
                          <div className="flex justify-between">
                            <div>
                              {index + 1}.{" "}
                              {image
                                .split("/")
                                .slice(-2)
                                .join("/")
                                .split(".")[0]}
                            </div>
                            <div>
                              {isEdit && (
                                <button
                                  className="text-red-500 hover:text-red-700 transition"
                                  onClick={() => {
                                    handleImageDelete(image);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrash} size="md" />
                                </button>
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              )}

                  <div className="mb-3">
                    <label className="block text-gray-700">Status</label>
                    <select
                      name="status"
                      value={currentShop.status || ""}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md  bg-slate-50 text-gray-700"
                    >
                      <option value="active">Active</option>
                      <option value="inActive">Inactive</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="block text-gray-700">Expires At</label>
                    <input
                      type="date"
                      name="expireDate"
                      value={
                        moment(currentShop.expireDate).format("YYYY-MM-DD") ||
                        ""
                      }
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md  bg-slate-50 text-gray-700"
                    />
                  </div>
                  <div className="mb-3 flex justify-center">
                    {/* <QRCode
                      id="qrcode"
                      width={10}
                      value={JSON.stringify(currentShop.businessName)}
                    /> */}
                  </div>
                  <div className="flex justify-end space-x-2">
                    <button
                      type="button"
                      onClick={closeModal}
                      className="bg-gray-300 text-gray-700 hover:bg-gray-400 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-blue-500 text-white hover:bg-blue-600 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {(totalShops >= 10 || currentPage > 1) && (
        <div className="flex justify-center my-4">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="mx-2 px-4 py-2 bg-red-500 rounded text-white"
              >
                <FontAwesomeIcon icon={faChevronCircleLeft} /> Previous
              </button>
              <span className="mx-2 px-4 py-2 text-gray-700"> 
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="mx-2 px-4 py-2 bg-green-500 rounded text-white"
              >
                Next <FontAwesomeIcon icon={faChevronCircleRight} />
              </button>
            </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default ManageRestraunts;
