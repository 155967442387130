import React, { useState, useEffect } from "react";
import Sidebar from "./common/Sidebar";
import Navbar from "./common/Navbar";
import { ContentsState } from "../Context/ContentProvider";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faYoutube,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const ManageSocialMedia = ({ selectedUser }) => {
  const [twitter, setTwitter] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYoutube] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const { user } = ContentsState();
  const [currentUser, setCurrentUser] = useState(null);
  // const baseUrl = "http://localhost:5000";
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const { isDarkMode, toggleDarkMode } = ContentsState();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (selectedUser) {
      setCurrentUser(selectedUser);
    } else {
      setCurrentUser(user);
    }
  }, [selectedUser, user]);

  useEffect(() => {
    if (currentUser) {
      fetchData();
      console.log(currentUser)
    }
  }, [currentUser]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/socialmedia`, {
        params: { url: currentUser.url },
      });
      const { data } = response;
      if (data) {
        setTwitter(data.twitter);
        setFacebook(data.facebook);
        setInstagram(data.instagram);
        setYoutube(data.youtube);
        setLinkedin(data.linkedin);
        setWhatsapp(data.whatsapp);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validate at least one field is filled
    if (!twitter && !facebook && !instagram && !youtube && !linkedin && !whatsapp) {
      toast.error("At least one social media link must be provided");
      return;
    }
  
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const url = `${baseUrl}/api/socialmedia/postsocialmedia`;
      const method = "post";
  
      await axios({
        method,
        url,
        data: {
          emailId: currentUser.email,
          twitter: twitter,
          facebook: facebook,
          instagram: instagram,
          youtube: youtube,
          linkedin: linkedin,
          whatsapp: whatsapp,
          user: currentUser,
        },
        headers: config.headers,
      });
  
      toast.success("Social media links updated successfully");
      fetchData();
    } catch (err) {
      console.error(err);
      toast.error("Error submitting data");
    }
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "twitter":
        setTwitter(value);
        break;
      case "facebook":
        setFacebook(value);
        break;
      case "instagram":
        setInstagram(value);
        break;
      case "youtube":
        setYoutube(value);
        break;
      case "linkedin":
        setLinkedin(value);
        break;
      case "whatsapp":
        setWhatsapp(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className={`flex h-screen bg-gray-200 overflow-hidden ${isDarkMode ? "dark-mode" : ""}`}>
      {!selectedUser && (
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      )}
      <div
        className={`flex-1 flex flex-col ${
          isOpen ? "ml-0" : ""
        } overflow-y-auto`}
      >
        {!selectedUser && (
          <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen}  toggleDarkMode={toggleDarkMode}/>
        )}
        <div className="p-4">
          <h1
            className="text-2xl font-bold mb-4 uppercase  text-gray-700"
            style={{ textAlign: "center" }}
          >
            Manage Social Media
          </h1>
          <form onSubmit={handleSubmit} style={{ marginTop: "30px" }}>
            <div className="space-y-5">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="flex items-center space-x-5 space-y-5">
                  <FontAwesomeIcon icon={faTwitter} size="2x" color="#1DA1F2" />
                  <input
                    type="text"
                    name="twitter"
                    value={twitter}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded  bg-slate-50 text-gray-700"
                    placeholder="Twitter Link"
                  />
                </div>
                <div className="flex items-center space-x-5 space-y-5">
                  <FontAwesomeIcon
                    icon={faFacebook}
                    size="2x"
                    color="#0a66c2"
                  />
                  <input
                    type="text"
                    name="facebook"
                    value={facebook}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded  bg-slate-50 text-gray-700"
                    placeholder="Facebook Link"
                  />
                </div>
                <div className="flex items-center space-x-5 space-y-5">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="2x"
                    color="#dc3545"
                  />
                  <input
                    type="text"
                    name="instagram"
                    value={instagram}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded  bg-slate-50 text-gray-700"
                    placeholder="Instagram Link"
                  />
                </div>
                <div className="flex items-center space-x-5 space-y-5">
                  <FontAwesomeIcon icon={faYoutube} size="2x" color="red" />
                  <input
                    type="text"
                    name="youtube"
                    value={youtube}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded  bg-slate-50 text-gray-700"
                    placeholder="YouTube Link"
                  />
                </div>
                <div className="flex items-center space-x-5 space-y-5">
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    size="2x"
                    color="#0a66c2"
                  />
                  <input
                    type="text"
                    name="linkedin"
                    value={linkedin}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded  bg-slate-50 text-gray-700"
                    placeholder="LinkedIn Link"
                  />
                </div>
                <div className="flex items-center space-x-5 space-y-5">
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    size="2x"
                    color="#25D366"
                  />
                  <input
                    type="text"
                    name="whatsapp"
                    value={whatsapp}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded  bg-slate-50 text-gray-700"
                    placeholder="WhatsApp Number"
                  />
                </div>
              </div>
              <div className="flex justify-center mt-12">
                <button
                  type="submit"
                  className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ManageSocialMedia;
