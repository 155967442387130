import React, { useState, useEffect } from "react";
import Sidebar from "./common/Sidebar";
import Navbar from "./common/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ContentsState } from "../Context/ContentProvider";
import axios from "axios";

const ManagePassword = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const { user } = ContentsState();
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const { isDarkMode, toggleDarkMode } = ContentsState();
  useEffect(() => {
    setPasswordMatch(passwords.newPassword === passwords.confirmPassword);
  }, [passwords.newPassword, passwords.confirmPassword]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPasswords({
      ...passwords,
      [name]: value,
    });
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword({
      ...showPassword,
      [field]: !showPassword[field],
    });
  };

  const validatePassword = (password) => {
    const uppercase = /[A-Z]/;
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/;
    return uppercase.test(password) && specialChar.test(password);
  };

  const handleSubmit = (e) => {
    const postReq = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };

        const { data } = await axios.put(
          `${baseUrl}/api/user/changepassword`,
          {
            email: user.email,
            password: passwords.oldPassword,
            newPassword: passwords.newPassword,
          },
          config
        );
        toast.success(data);
      } catch (err) {
        toast.error(err.response.data);
      }
    };

    e.preventDefault();
    const { oldPassword, newPassword, confirmPassword } = passwords;

    if (!oldPassword || !newPassword || !confirmPassword) {
      toast.error("All fields must be filled.");
      return;
    }

    if (!validatePassword(newPassword)) {
      setPasswordValid(false);
      toast.error(
        "New password must contain at least one uppercase letter and one special character."
      );
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password do not match.");
      return;
    }

    setPasswordValid(true);
    // Proceed with form submission logic (e.g., API call)
    postReq();
  };

  return (
    <div className={`flex h-screen bg-gray-400 w-full ${isDarkMode ? "dark-mode" : ""}`}>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div
        className={`flex-1 flex flex-col ${
          isOpen ? "ml-0" : ""
        } overflow-y-auto`}
      >
        <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen} toggleDarkMode={toggleDarkMode} />
        <div className="p-4">
          <h1 className="text-2xl font-bold mb-4 uppercase text-center text-gray-700">
            Manage Password
          </h1>
          <div className="max-w-md mx-auto bg-white p-6 rounded-md shadow-md">
            <form onSubmit={handleSubmit}>
              {["oldPassword", "newPassword", "confirmPassword"].map(
                (field, index) => (
                  <div key={index} className="mb-4">
                    <label className="block text-gray-700 mb-2 capitalize font-bold">
                      {field.split(/(?=[A-Z])/).join(" ")}
                    </label>
                    <div className="relative">
                      <input
                        type={showPassword[field] ? "text" : "password"}
                        name={field}
                        value={passwords[field]}
                        onChange={handleInputChange}
                        className={`w-full px-4 py-2 border rounded-md focus:outline-none  text-gray-700 bg-slate-50 focus:ring-2 ${
                          field === "confirmPassword" && !passwordMatch
                            ? "border-red-500 focus:ring-red-400"
                            : field !== "oldPassword" && passwordMatch
                            ? "border-green-500 focus:ring-green-400"
                            : "focus:ring-blue-400"
                        } ${
                          field === "newPassword" && !passwordValid
                            ? "border-red-500 focus:ring-red-400"
                            : ""
                        }`}
                      />
                      <span
                        onClick={() => togglePasswordVisibility(field)}
                        className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                      >
                        <FontAwesomeIcon
                          icon={showPassword[field] ? faEyeSlash : faEye} className=" text-gray-700"
                        />
                      </span>
                    </div>
                  </div>
                )
              )}
              <button
                type="submit"
                className="w-full px-4 py-2 bg-blue-500 text-white font-bold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ManagePassword;
