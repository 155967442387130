import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPlus,
  faClose,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "./common/Sidebar";
import Navbar from "./common/Navbar";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { ContentsState } from "../Context/ContentProvider";

function ManageSection() {
  const [isOpen, setIsOpen] = useState(true);
  const { user } = ContentsState();
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [htmlContent, setHtmlContent] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUrl, setSelectedUrl] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const { isDarkMode, toggleDarkMode } = ContentsState();

  const [totalPages, setTotalPages] = useState(0);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const fetchData = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };
      const response = await axios.get(
        `${baseUrl}/api/user/?page=${currentPage}`,
        config
      );
      const { data } = response;
      setUsers(data.users);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);
  useEffect(() => {
    fetchData();
  }, []);

  const handleAddSection = (userId, url) => {
    const fetchSectionData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/section`, {
          params: { url },
        });
        const { data } = response;

        if (data) {
          setHtmlContent((prevState) => ({
            ...prevState,
            [data.emailId]: data.sectionContent,
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
      }
    };
    setSelectedUser(userId);
    setSelectedUrl(url);
    setIsFormVisible(true);
    fetchSectionData();
  };

  const handleHtmlChange = (content, userId) => {
    setHtmlContent((prevState) => ({
      ...prevState,
      [userId]: content,
    }));
  };

  const handleSubmit = async (userId) => {
    const postSectionData = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };
        const postData = {
          emailId: selectedUser,
          sectionContent: htmlContent[selectedUser],
        };
        const response = await axios.post(
          `${baseUrl}/api/section/postSection`,
          postData,
          config
        );
        console.log(response.data); // Log response data if needed
        toast.success("Upload Success");
      } catch (err) {
        console.error(err);
        toast.error("Error submitting data");
      }
    };
    postSectionData();
    setIsFormVisible(false);
    setSelectedUser(null);
    setSelectedUrl("");
  };

  const handleBackToTable = () => {
    setIsFormVisible(false);
    setSelectedUser(null);
    setSelectedUrl("");
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (user.role !== "admin") {
    return <h1>You are not authorized to access this page</h1>;
  }

  return (
    <div className={`flex h-screen bg-gray-200 w-full ${isDarkMode ? "dark-mode" : ""}`}>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />

      <div
        className={`flex-1 flex flex-col ${
          isOpen ? "ml-0" : ""
        } overflow-y-auto`}
      >
        <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen}  toggleDarkMode={toggleDarkMode}  />
        <div className="p-4">
          <h1 className="text-2xl font-bold mb-0 uppercase text-gray-700">
            {isFormVisible ? (
              <span className="capitalize  text-gray-700">
                Adding Section for
                <span
                  className="text-green-800 px-2 capitalize"
                  style={{ textAlign: "center" }}
                >
                  {selectedUrl}
                </span>
              </span>
            ) : (
              <span>Manage Section</span>
            )}
          </h1>
        </div>
        <div>
          {isFormVisible ? (
            <div className="p-4 mt-0 rounded">
              <textarea
                value={htmlContent[selectedUser] || ""}
                onChange={(e) => handleHtmlChange(e.target.value, selectedUser)}
                className="mb-12 w-full h-64 p-2 border bg-vscode-dark text-white vscode"
                style={{
                  height: "500px",
                  fontFamily:
                    "Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
                }}
              />
              <button
                onClick={handleSubmit}
                className="bg-green-500 text-white px-4 py-2 rounded"
              >
                Submit <FontAwesomeIcon icon={faCheck} />
              </button>
              <button
                onClick={handleBackToTable}
                className="bg-red-500 text-white px-4 py-2 rounded ml-2"
              >
                Close <FontAwesomeIcon icon={faClose} />
              </button>
            </div>
          ) : (
            <>
              <table className="table-auto w-full bg-white  text-gray-700">
                <thead className="text-left">
                  <tr>
                    <th className="py-2 px-4 border-b">Business Name</th>
                    <th className="py-2 px-4 border-b">Email</th>
                    <th className="py-2 px-4 border-b">Business URL</th>
                    <th className="py-2 px-4 border-b">Status</th>
                    <th className="py-2 px-4 border-b">Action</th>
                  </tr>
                </thead>
                <tbody className="text-left">
                  {users.map((user) => (
                    <tr key={user._id}>
                      <td className="py-2 px-4 border-b ">
                        {user.companyName}
                      </td>
                      <td className="py-2 px-4 border-b">
                        {user.email}
                      </td>
                      <td className="py-2 px-4 border-b ">
                        {user.url}
                      </td>
                      <td className="py-2 px-4 border-b ">
                        <span
                          className={
                            user.status === "active"
                              ? "bg-green-500 text-white px-4 py-1 rounded"
                              : "bg-red-500 text-white px-2 py-1 rounded"
                          }
                        >
                          {user.status}
                        </span>
                      </td>
                      <td className="py-2 px-4 border-b ">
                        <button
                          onClick={() => handleAddSection(user.email, user.url)}
                          className="bg-blue-500 text-white px-4 py-1 rounded"
                        >
                          Add Section <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex justify-center my-4">
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className="mx-2 px-4 py-2 bg-red-500 rounded text-white"
                >
                  <FontAwesomeIcon icon={faChevronLeft} /> Previous
                </button>
                <span className="mx-2 px-4 py-2  text-gray-700">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className="mx-2 px-4 py-2 bg-green-500 rounded text-white"
                >
                  Next <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ManageSection;
