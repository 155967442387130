import React, { useState } from "react";
import { BrowserRouter, NavLink } from "react-router-dom";
import Sidebar from "../components/common/Sidebar";
import Navbar from "../components/common/Navbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faImages, faGears, faBlog, faBell, faPalette } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebook, faInstagram, faYoutube, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { ContentsState } from "../Context/ContentProvider";

function CMSPanel() {
  const [isOpen, setIsOpen] = useState(false);
  const { isDarkMode, toggleDarkMode } = ContentsState();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };



  return (
    <div className={`flex h-screen bg-gray-200 w-full ${isDarkMode ? "dark-mode" : ""}`}>
        {/* Sidebar */}
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
        
        {/* Main content */}
        <div className="flex-1 flex flex-col ml-0 transition-all duration-300 ease-in-out overflow-y-auto">
          <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen} toggleDarkMode={toggleDarkMode}/>

          <div className="flex flex-col items-center justify-center p-6 bg-card text-gray-800 shadow-md rounded-lg m-4 flex-grow">
            <img src="https://designblocks.in/img/DB.png" alt="Logo" className="h-48 mb-6" />
            <p className="text-lg mb-8 text-center font-serif text-gray-700">
              Welcome! You can manage your content here. Explore the features and make the most out of your content management experience.
            </p>
            
            {/* Links grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full max-w-4xl">
            <NavLink to="/manageabout" className={`rounded-lg shadow-md p-6 flex items-center space-x-4 hover:bg-gray-100 transition ${isDarkMode ? "bg-gray-800 hover:bg-gray-600" : "bg-white"}`}>
          <FontAwesomeIcon icon={faInfoCircle} className="text-blue-500" />
          <span className={`font-bold ${isDarkMode ? "text-white" : "text-gray-700"}`}>Manage About</span>
            </NavLink>
            <NavLink to="/manageslide" className={`rounded-lg shadow-md p-6 flex items-center space-x-4 hover:bg-gray-100 transition ${isDarkMode ? "bg-gray-800 hover:bg-gray-600" : "bg-white"}`}>
              <FontAwesomeIcon icon={faImages} className="text-green-500" />
              <span className={`font-bold ${isDarkMode ? "text-white" : "text-gray-700"}`}>Manage Slide</span>
            </NavLink>
            <NavLink to="/manageservices" className={`rounded-lg shadow-md p-6 flex items-center space-x-4 hover:bg-gray-100 transition ${isDarkMode ? "bg-gray-800 hover:bg-gray-600" : "bg-white"}`}>
              <FontAwesomeIcon icon={faGears} className="text-yellow-500" />
              <span className={`font-bold ${isDarkMode ? "text-white" : "text-gray-700"}`}>Manage Services</span>
            </NavLink>
            <NavLink to="/managegallery" className={`rounded-lg shadow-md p-6 flex items-center space-x-4 hover:bg-gray-100 transition ${isDarkMode ? "bg-gray-800 hover:bg-gray-600" : "bg-white"}`}>
              <FontAwesomeIcon icon={faImages} className="text-purple-500" />
              <span className={`font-bold ${isDarkMode ? "text-white" : "text-gray-700"}`}>Manage Gallery</span>
            </NavLink>
            <NavLink to="/manageblog" className={`rounded-lg shadow-md p-6 flex items-center space-x-4 hover:bg-gray-100 transition ${isDarkMode ? "bg-gray-800 hover:bg-gray-600" : "bg-white"}`}>
              <FontAwesomeIcon icon={faBlog} className="text-red-500" />
              <span className={`font-bold ${isDarkMode ? "text-white" : "text-gray-700"}`}>Manage Blog</span>
            </NavLink>
            <NavLink to="/managenotification" className={`rounded-lg shadow-md p-6 flex items-center space-x-4 hover:bg-gray-100 transition ${isDarkMode ? "bg-gray-800 hover:bg-gray-600" : "bg-white"}`}>
              <FontAwesomeIcon icon={faBell} className="text-orange-500" />
              <span className={`font-bold ${isDarkMode ? "text-white" : "text-gray-700"}`}>Manage Notification</span>
            </NavLink>
            <NavLink to="/managecolorcode" className={`rounded-lg shadow-md p-6 flex items-center space-x-4 hover:bg-gray-100 transition ${isDarkMode ? "bg-gray-800 hover:bg-gray-600" : "bg-white"}`}>
              <FontAwesomeIcon icon={faPalette} className="text-pink-500" />
              <span className={`font-bold ${isDarkMode ? "text-white" : "text-gray-700"}`}>Manage Color Code</span>
            </NavLink>
            <NavLink to="/managesocialmedia" className={`rounded-lg shadow-md p-6 flex items-center space-x-4 hover:bg-gray-100 transition ${isDarkMode ? "bg-gray-800 hover:bg-gray-600" : "bg-white"}`}>
              <FontAwesomeIcon icon={faTwitter} className="text-blue-400" />
              <span className={`font-bold ${isDarkMode ? "text-white" : "text-gray-700"}`}>Manage Social Media</span>
            </NavLink>
            </div>
          </div>
        </div>
      </div>
  );
}

export default CMSPanel;
