import React, { useEffect, useState } from "react";
import Sidebar from "./common/Sidebar";
import Navbar from "./common/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { ContentsState } from "../Context/ContentProvider";
import axios from "axios";

const ManageColorCode = ({ selectedUser }) => {
  const [color, setColor] = useColor("hex", "");
  const [navbarColor, setNavbarColor] = useState("");
  const [navTextColor, setNavTextColor] = useState("");
  const [navHoverColor, setNavHoverColor] = useState("");
  const [footerColor, setFooterColor] = useState("");
  const [headingColor, setHeadingColor] = useState("");
  const [paragraphColor, setParagraphColor] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const { user } = ContentsState();
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const { isDarkMode, toggleDarkMode } = ContentsState();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (selectedUser) {
      setCurrentUser(selectedUser);
    } else {
      setCurrentUser(user);
    }
  }, [selectedUser, user]);
  useEffect(() => {
    if (currentUser) {
      fetchData();
    }
  }, [currentUser]);
  const handleSetDefaultColors = () => {
    setNavbarColor("#ffffff");
    setNavTextColor("#485664");
    setNavHoverColor("#0ea2bd");
    setFooterColor("#3a4753");
    setHeadingColor("#173b6c");
    setParagraphColor("#565656");
    toast.success("Default colors set! Now Click on Save Colors");
  };

  const handleColorChange = (newColor) => {
    setColor(newColor);
    switch (selectedField) {
      case "navbarColor":
        setNavbarColor(newColor.hex);
        break;
      case "navTextColor":
        setNavTextColor(newColor.hex);
        break;
      case "navHoverColor":
        setNavHoverColor(newColor.hex);
        break;
      case "footerColor":
        setFooterColor(newColor.hex);
        break;
      case "headingColor":
        setHeadingColor(newColor.hex);
        break;
      case "paragraphColor":
        setParagraphColor(newColor.hex);
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    const postReq = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };
        const postData = {
          emailId: currentUser.email,
          navBar: navbarColor,
          navBarText: navTextColor,
          navBarHover: navHoverColor,
          footer: footerColor,
          heading: headingColor,
          para: paragraphColor,
        };
        const response = await axios.post(
          `${baseUrl}/api/color/postColor`,
          postData,
          config
        );
        console.log(response.data); // Log response data if needed
        toast.success("Updated Successfully");
      } catch (err) {
        console.error(err);
        toast.error("Error submitting data");
      }
    };
    if (
      !navbarColor ||
      !navHoverColor ||
      !navTextColor ||
      !footerColor ||
      !headingColor ||
      !paragraphColor
    ) {
      toast.error("Fill all the fields");
      return;
    }
    postReq();
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/color`, {
        params: { url: currentUser.url },
      });
      const { data } = response;
      if (data) {
        setNavbarColor(data.navBar);
        setNavHoverColor(data.navBarHover);
        setNavTextColor(data.navBarText);
        setFooterColor(data.footer);
        setHeadingColor(data.heading);
        setParagraphColor(data.para);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };

  return (
    <div className={`flex h-screen bg-gray-200 overflow-hidden ${isDarkMode ? "dark-mode" : ""}`}>
      {!selectedUser && (
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      )}
      <div
        className={`flex-1 flex flex-col ${
          isOpen ? "ml-0" : ""
        } overflow-y-auto`}
      >
        {!selectedUser && (
          <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen}  toggleDarkMode={toggleDarkMode} />
        )}
        <div className="p-4">
          <h1
            className="text-2xl font-bold mb-4 uppercase  text-gray-700"
            style={{ textAlign: "center" }}
          >
            Manage Color Code
          </h1>
          <div className="mb-4  w-full flex justify-center">
            <ColorPicker
              width={250}
              height={130}
              color={color}
              onChange={handleColorChange}
              hideHSV
              dark
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Navbar Color
              </label>
              <input
                type="text"
                value={navbarColor}
                onFocus={() => setSelectedField("navbarColor")}
                onChange={(e) => setNavbarColor(e.target.value)}
                className="p-2 border border-gray-300 rounded w-full mt-2  bg-slate-50 text-gray-700"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Navbar Text Color
              </label>
              <input
                type="text"
                value={navTextColor}
                onFocus={() => setSelectedField("navTextColor")}
                onChange={(e) => setNavTextColor(e.target.value)}
                className="p-2 border border-gray-300 rounded w-full mt-2  bg-slate-50 text-gray-700"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Navbar Hover Color
              </label>
              <input
                type="text"
                value={navHoverColor}
                onFocus={() => setSelectedField("navHoverColor")}
                onChange={(e) => setNavHoverColor(e.target.value)}
                className="p-2 border border-gray-300 rounded w-full mt-2  bg-slate-50 text-gray-700"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Footer Color
              </label>
              <input
                type="text"
                value={footerColor}
                onFocus={() => setSelectedField("footerColor")}
                onChange={(e) => setFooterColor(e.target.value)}
                className="p-2 border border-gray-300 rounded w-full mt-2  bg-slate-50 text-gray-700"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Heading Color
              </label>
              <input
                type="text"
                value={headingColor}
                onFocus={() => setSelectedField("headingColor")}
                onChange={(e) => setHeadingColor(e.target.value)}
                className="p-2 border border-gray-300 rounded w-full mt-2  bg-slate-50 text-gray-700"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Paragraph Color
              </label>
              <input
                type="text"
                value={paragraphColor}
                onFocus={() => setSelectedField("paragraphColor")}
                onChange={(e) => setParagraphColor(e.target.value)}
                className="p-2 border border-gray-300 rounded w-full mt-2  bg-slate-50 text-gray-700"
              />
            </div>
          </div>
          <div className="flex justify-center md:justify-start space-x-4">
            <button
              onClick={handleSubmit}
              className="bg-blue-500 text-white p-2 mt-5 rounded"
            >
              Save Colors
            </button>
            <button
              onClick={handleSetDefaultColors}
              className="bg-gray-500 text-white p-2 mt-5 rounded"
            >
              Set Default Colors
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ManageColorCode;

// import React, { useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCheck,
//   faPlus,
//   faClose,
//   faChevronLeft,
//   faChevronRight,
// } from "@fortawesome/free-solid-svg-icons";
// import Sidebar from "./Sidebar";
// import Navbar from "./Navbar";
// import { ToastContainer, toast } from "react-toastify";
// import axios from "axios";
// import { ContentsState } from "../Context/ContentProvider";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";

// function ManageSection() {
//   const [isOpen, setIsOpen] = useState(true);
//   const { user } = ContentsState();
//   const [users, setUsers] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [htmlContent, setHtmlContent] = useState({});
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [selectedCompanyName, setSelectedCompanyName] = useState("");
//   const [isFormVisible, setIsFormVisible] = useState(false);
//   const itemsPerPage = 10;

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentUsers = users.slice(indexOfFirstItem, indexOfLastItem);

//   const totalPages = Math.ceil(users.length / itemsPerPage);

//   const toggleSidebar = () => {
//     setIsOpen(!isOpen);
//   };

//   const fetchData = async () => {
//     try {
//       const config = {
//         headers: {
//           "Content-type": "application/json",
//           Authorization: "Bearer " + user.token,
//         },
//       };
//       const response = await axios.get(
//         "https://cmmserver-78e2cb092014.herokuapp.com/api/user/",
//         config
//       );
//       const { data } = response;
//       setUsers([...data]);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       toast.error("Error fetching data");
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const handleAddSection = (userId, companyName) => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get("https://cmmserver-78e2cb092014.herokuapp.com/api/section", {
//           params: { url: companyName },
//         });
//         const { data } = response;

//         if (data) {
//           setHtmlContent((prevState) => ({
//             ...prevState,
//             [data.emailId]: data.sectionContent,
//           }));
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//         toast.error("Error fetching data");
//       }
//     };
//     setSelectedUser(userId);
//     setSelectedCompanyName(companyName);
//     setIsFormVisible(true);
//     fetchData();
//     console.log(htmlContent);
//   };

//   const handleHtmlChange = (content, userId) => {
//     setHtmlContent((prevState) => ({
//       ...prevState,
//       [userId]: content,
//     }));
//   };

//   const handleSubmit = async (userId) => {
//     const postReq = async () => {
//       try {
//         const config = {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: "Bearer " + user.token,
//           },
//         };
//         const postData = {
//           emailId: selectedUser,
//           sectionContent: htmlContent[selectedUser],
//         };
//         const response = await axios.post(
//           "https://cmmserver-78e2cb092014.herokuapp.com/api/section/postSection",
//           postData,
//           config
//         );
//         console.log(response.data); // Log response data if needed
//         toast.success("Upload Success");
//       } catch (err) {
//         console.error(err);
//         toast.error("Error submitting data");
//       }
//     };
//     postReq();
//     setIsFormVisible(false);
//     setSelectedUser(null);
//     setSelectedCompanyName("");
//   };

//   const handleBackToTable = () => {
//     setIsFormVisible(false);
//     setSelectedUser(null);
//     setSelectedCompanyName("");
//   };

//   const handleNextPage = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const handlePreviousPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   if (user.role !== "admin") {
//     return <h1>You are not authorized to access this page</h1>;
//   }

//   return (
//     <div className="flex h-screen bg-gray-200 w-full">
//       <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />

//       <div
//         className={`flex-1 flex flex-col ${
//           isOpen ? "ml-0" : ""
//         } overflow-y-auto`}
//       >
//         <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen} />
//         <div className="p-4">
//           <h1 className="text-2xl font-bold mb-0 uppercase">
//             {isFormVisible ? (
//               <span className="capitalize">
//                 Adding Section for
//                 <span
//                   className="text-green-800 px-2 capitalize"
//                   style={{ textAlign: "center" }}
//                 >
//                   {selectedCompanyName}
//                 </span>
//               </span>
//             ) : (
//               <span>Manage Section</span>
//             )}
//           </h1>
//         </div>
//         <div>
//           {isFormVisible ? (
//             <div className="p-4 mt-0 rounded">
//               <ReactQuill
//                 value={htmlContent[selectedUser] || ""}
//                 onChange={(content) => handleHtmlChange(content, selectedUser)}
//                 className="mb-12 my-quill-editor"
//                 style={{ height: "500px" }}
//               />
//               <button
//                 onClick={() => handleSubmit(selectedUser)}
//                 className="bg-green-500 text-white px-4 py-2 rounded"
//               >
//                 Submit <FontAwesomeIcon icon={faCheck} />
//               </button>
//               <button
//                 onClick={handleBackToTable}
//                 className="bg-red-500 text-white px-4 py-2 rounded ml-2"
//               >
//                 Close <FontAwesomeIcon icon={faClose} />
//               </button>
//             </div>
//           ) : (
//             <>
//               <table className="table-auto w-full bg-white">
//                 <thead>
//                   <tr>
//                     <th className="py-2 px-4 border-b">Company Name</th>
//                     <th className="py-2 px-4 border-b">Email</th>
//                     <th className="py-2 px-4 border-b">URL</th>
//                     <th className="py-2 px-4 border-b">Status</th>
//                     <th className="py-2 px-4 border-b">Action</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {currentUsers.map((user) => (
//                     <tr key={user._id}>
//                       <td className="py-2 px-4 border-b text-center">
//                         {user.companyName}
//                       </td>
//                       <td className="py-2 px-4 border-b text-center">
//                         {user.email}
//                       </td>
//                       <td className="py-2 px-4 border-b text-center">
//                         {user.url}
//                       </td>
//                       <td className="py-2 px-4 border-b text-center">
//                         <span
//                           className={
//                             user.status === "active"
//                               ? "bg-green-500 text-white px-4 py-1 rounded"
//                               : "bg-red-500 text-white px-2 py-1 rounded"
//                           }
//                         >
//                           {user.status}
//                         </span>
//                       </td>
//                       <td className="py-2 px-4 border-b text-center">
//                         <button
//                           onClick={() =>
//                             handleAddSection(user.email, user.companyName)
//                           }
//                           className="bg-blue-500 text-white px-4 py-1 rounded"
//                         >
//                           Add Section <FontAwesomeIcon icon={faPlus} />
//                         </button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>

//               <div className="flex justify-center my-4">
//                 <button
//                   onClick={handlePreviousPage}
//                   disabled={currentPage === 1}
//                   className="mx-2 px-4 py-2 bg-red-500 rounded text-white"
//                 >
//                   <FontAwesomeIcon icon={faChevronLeft} /> Previous
//                 </button>
//                 <span className="mx-2 px-4 py-2">
//                   Page {currentPage} of {totalPages}
//                 </span>
//                 <button
//                   onClick={handleNextPage}
//                   disabled={currentPage === totalPages}
//                   className="mx-2 px-4 py-2 bg-green-500 rounded text-white"
//                 >
//                   Next <FontAwesomeIcon icon={faChevronRight} />
//                 </button>
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//       <ToastContainer />
//     </div>
//   );
// }

// export default ManageSection;
