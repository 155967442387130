import React, { useState, useEffect } from "react";
import Sidebar from "./common/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Navbar from "./common/Navbar";
import { ContentsState } from "../Context/ContentProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faPlus,
  faChevronLeft,
  faChevronRight,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import AdminActions from "./AdminPages/AdminActions";
import moment from "moment-timezone";

function ManageBusiness() {
  const [isOpen, setIsOpen] = useState(true);
  const { user } = ContentsState();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [tableVisibility, setTableVisibility] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  // const baseUrl = "http://localhost:5000";
  const { isDarkMode, toggleDarkMode } = ContentsState();

  const [totalPages, setTotalPages] = useState(0);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const handleBackToTable = () => {
    setTableVisibility(false);
    setSelectedUser(null);
  };

  const fetchData = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };
      const response = await axios.get(
        `${baseUrl}/api/user?page=${currentPage}`,
        config
      );
      const { data } = response;
      setUsers(data.users);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);
  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (user) => {
    setSelectedUser(user);
    setTableVisibility(true);
  };

  if (user.role !== "admin") {
    return <h1>You are not authorized to access this page</h1>;
  }

  return (
    <div className={`flex h-screen bg-gray-200 w-full ${isDarkMode ? "dark-mode" : ""}`}>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      {tableVisibility ? (
        <div
          className={`flex-1 flex flex-col ${
            isOpen ? "ml-0" : ""
          } overflow-y-auto`}
        >
          <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen}  toggleDarkMode={toggleDarkMode}  />
          <div className="p-4 mt-0 rounded w-full">
            <button
              onClick={handleBackToTable}
              className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition duration-200 ease-in-out ml-2"
            >
              <FontAwesomeIcon icon={faChevronLeft} /> Go Back
            </button>
            <div className="mt-4">
              <AdminActions selectedUser={selectedUser} />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`flex-1 flex flex-col ${
            isOpen ? "ml-0" : ""
          } overflow-y-auto`}
        >
          <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen} />
          <div className="p-4">
            <h1
              className="text-2xl font-bold mb-4 uppercase  text-gray-700"
              style={{ textAlign: "" }}
            >
              Manage Businesses
            </h1>
          </div>
          <div>
            <table className="table-auto w-full  bg-white  text-gray-700">
              <thead className="text-left">
                <tr>
                  <th className="py-2 px-4 border-b">Company Name</th>
                  <th className="py-2 px-4 border-b">Business URL</th>
                  <th className="py-2 px-4 border-b">Created Date</th>
                  <th className="py-2 px-4 border-b">Last Modified</th>
                  <th className="py-2 px-4 border-b">Manage Business</th>
                </tr>
              </thead>
              <tbody className="text-left">
                {users.map((user) => (
                  <tr key={user._id}>
                    <td className="py-2 px-4 border-b ">
                      {user.companyName}
                    </td>
                    <td className="py-2 px-4 border-b ">
                      {user.url}
                    </td>
                    <td className="py-2 px-4 border-b ">
                      {moment
                        .utc(user.createdAt)
                        .tz("Asia/Kolkata")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {moment
                        .utc(user.updatedAt)
                        .tz("Asia/Kolkata")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                    <td className="py-2 px-4 border-b ">
                      <button
                        onClick={() => {
                          handleEdit(user);
                        }}
                        className="bg-yellow-500 text-white px-4 py-1 rounded"
                      >
                        Manage <FontAwesomeIcon icon={faEdit} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex justify-center my-4">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="mx-2 px-4 py-2 bg-red-500 rounded text-white"
              >
                <FontAwesomeIcon icon={faChevronLeft} /> Previous
              </button>
              <span className="mx-2 px-4 py-2 text-gray-700"> 
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="mx-2 px-4 py-2 bg-green-500 rounded text-white"
              >
                Next <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default ManageBusiness;
