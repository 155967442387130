import React, { useEffect, useState, useRef } from "react";
import QRCode from "qrcode.react";
import Sidebar from "./common/Sidebar";
import Navbar from "./common/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContentsState } from "../Context/ContentProvider";
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faYoutube,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  faAdd,
  faCheck,
  faCirclePlus,
  faClose,
  faEdit,
  faEye,
  faFileCirclePlus,
  faPlus,
  faQrcode,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Swal from "sweetalert2";
const initialShops = [
  // {
  //   guid: "01",
  //   status: "active",
  //   landingImage: "url",
  //   coupleImages: {
  //     bride: "ur1",
  //     groom: "url2",
  //   },
  //   contactNumber: "1234",
  //   gallery: ["imageId", "imageId2"],
  //   marriageDate: Date.now(),
  //   lunchDate: Date.now(),
  //   expireDate: Date.now() + 10,
  //   address: {
  //     functionHallName: "shanti hall",
  //     place: "Nurali Nagar",
  //     city: "Visakhapatnam",
  //     state: "Andhra Pradesh",
  //   },
  //   mapURL: "mapurl",
  //   sectionContent: "sectionContent",
  //   coupleNames: {
  //     bride: "Tanisha",
  //     groom: "Tarun",
  //   },
  // },
];
const ManageWedding = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [shops, setShops] = useState(initialShops);
  const [currentShop, setCurrentShop] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const { user } = ContentsState();
  const [isEdit, setIsEdit] = useState(false);
  const { isDarkMode, toggleDarkMode } = ContentsState();
  const [htmlContent, setHtmlContent] = useState();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUrl, setSelectedUrl] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isUploading, setIsUploading] = useState(false); // State to track uploading status
  const canvasRefs = useRef({});
  const openModal = (shop) => {
    setCurrentShop(shop);
    setModalOpen(true);
  };

  const closeModal = () => {
    if (isEdit) {
      setIsEdit(false);
    }
    setCurrentShop(null);
    setModalOpen(false);
  };

  const saveShop = (shop) => {
    if (shop.id) {
      setShops(shops.map((s) => (s.id === shop.id ? shop : s)));
    } else {
      shop.id = shops.length + 1;
      setShops([...shops, shop]);
    }
    closeModal();
  };

  const handleChange = async (e) => {
    // console.log(currentShop);
    const { name, value, files } = e.target;
    // console.log(name);

    if (
      name === "imageUrls" ||
      name === "landingImage" ||
      name === "brideImage" ||
      name === "groomImage"
    ) {
      const validFileExtensions = ["-", "_", "."];
      const isValidFileName = (filename) => {
        // const baseName = filename.split(".").slice(0, -1).join(".");
        // const isValidLength = baseName.length < 8;
        // const hasValidCharacters = /^[a-zA-Z0-9-_\.]+$/.test(baseName);
        return true;
      };

      const validFiles = Array.from(files).filter((file) =>
        isValidFileName(file.name)
      );
      const urls = await Promise.all(
        validFiles.map(async (file) => await postDetails(file))
      );
      // Optionally, you can handle invalid files here if needed
      const invalidFiles = Array.from(files).filter(
        (file) => !isValidFileName(file.name)
      );

      if (invalidFiles.length > 0) {
        toast.error("Invalid File Name not processed");
      } else {
        if (name === "landingImage") {
          setCurrentShop({
            ...currentShop,
            landingImage: urls[0], // Assuming single file for landing image
          });
        } else if (name === "brideImage") {
          setCurrentShop({
            ...currentShop,
            coupleImages: {
              ...currentShop.coupleImages,
              bride: urls[0], // Assuming single file for bride image
            },
          });
        } else if (name === "groomImage") {
          setCurrentShop({
            ...currentShop,
            coupleImages: {
              ...currentShop.coupleImages,
              groom: urls[0], // Assuming single file for groom image
            },
          });
        } else if (name === "imageUrls") {
          // console.log("called");
          setCurrentShop((prevState) => ({
            ...prevState,
            gallery: [...(prevState.gallery || []), ...urls], // Ensure prevState.gallery is initialized as an array
          }));
        }
      }
    } else {
      switch (name) {
        case "brideName":
          setCurrentShop({
            ...currentShop,
            coupleNames: {
              ...currentShop.coupleNames,
              bride: value,
            },
          });
          break;
        case "groomName":
          setCurrentShop({
            ...currentShop,
            coupleNames: {
              ...currentShop.coupleNames,
              groom: value,
            },
          });
          break;
        case "functionHallName":
        case "place":
        case "city":
        case "state":
          setCurrentShop({
            ...currentShop,
            address: {
              ...currentShop.address,
              [name]: value,
            },
          });
          break;
        case "marriageDate":
        case "lunchDate":
        case "expireDate":
          setCurrentShop({
            ...currentShop,
            [name]: new Date(value).toISOString(),
          });
          break;
        default:
          setCurrentShop({
            ...currentShop,
            [name]: value,
          });
          break;
      }
    }
  };

  const handleSubmit = (e) => {
    const registerRest = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };

        const { data } = axios.post(
          `${baseUrl}/api/wedding/`,
          currentShop,
          config
        );
        if (data) toast.success("Data Submitted Successfully");
        setModalOpen(false);
        fetchData();
        // console.log(data);
      } catch (err) {
        console.error("Error Uploading Restraunt", err);
        toast.error("Error Occured");
      }
    };
    const editRest = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };

        const { data } = await axios.put(
          `${baseUrl}/api/wedding/${currentShop.guid}`,
          {
            currentShop,
          },
          config
        );

        if (data) toast.success("Data Updated Successfully");
        setModalOpen(false);
        fetchData();
        // console.log(data);
      } catch (err) {
        console.error("Error Updating Restaurant", err);
        toast.error("Error Occurred");
      }
    };
    // console.log(currentShop);
    if (!currentShop.coupleImages) {
      toast.error("Enter all the fields");
      return;
    }
    // console.log(currentShop);
    if (isEdit || isFormVisible) {
      editRest();
      if (isFormVisible) {
        setIsFormVisible(false);
      }
      fetchData();
    } else {
      registerRest();
    }
  };

  useEffect(() => {
    fetchData();
  }, [isModalOpen]);
  const fetchData = async () => {
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };
      const response = await axios.get(`${baseUrl}/api/wedding/`, config);
      const { data } = response;
      setShops(data.weddings);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };
  const handleBackToTable = () => {
    setCurrentShop({});
    setIsFormVisible(false);
  };
  const handleAddSection = (wed, userId, url) => {
    setCurrentShop(wed);
    setHtmlContent(currentShop ? currentShop.sectionContent : "");
    setIsFormVisible(true);
  };
  const handleHtmlChange = (content, userId) => {
    setCurrentShop({
      ...currentShop,
      sectionContent: content,
    });
  };

  const postDetails = async (file) => {
    if (!file || (file.type !== "image/jpeg" && file.type !== "image/png")) {
      toast.error("Invalid file type. Only JPEG and PNG are allowed.");
      return;
    }
    if (
      !currentShop.coupleNames ||
      (currentShop.coupleNames &&
        (!currentShop.coupleNames.bride || !currentShop.coupleNames.groom))
    ) {
      toast.error("Enter the couple Names before uploading images");
      return;
    }
    if (file.size > 2 * 1024 * 1024) {
      toast.error("Image size should be less than 2MB");
      return;
    }

    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "Content-Management");
    data.append("cloud_name", "ddpbayems");
    data.append(
      "folder",
      currentShop.coupleNames.bride.replace(/ /g, "-") +
        currentShop.coupleNames.groom.replace(/ /g, "-")
    ); // Dynamically setting folder name
    setIsUploading(true); // Set uploading status to true

    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/ddpbayems/image/upload",
        {
          method: "POST",
          body: data,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      const responseData = await response.json();
      const imageUrl = responseData.url;
      const publicId = imageUrl.split("/").slice(-2).join("/").split(".")[0];

        // 2-second delay before setting isUploading to false
        setTimeout(() => {
        setIsUploading(false);
      }, 500);

      return imageUrl;

 
    } catch (err) {
      console.log(err);
      toast.error("Error uploading image");
      setIsUploading(false); // Set uploading status to false in case of error
    }
  };
  const handleDelete = (shop) => {
    const deleteReq = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };

        const { data } = await axios.delete(
          `${baseUrl}/api/wedding/${shop.guid}`,
          config
        );

        if (data.success) {
          toast.success("Restaurant deleted successfully");
          // Optionally, update local state or redirect user
          // Example: setCurrentShop(null);
          fetchData();
        }
        // console.log(data);
      } catch (err) {
        console.error("Error Deleting Restaurant", err);
        toast.error("Error Occurred");
      }
    };
    if (
      shop.gallery > 0 ||
      (shop.coupleImages &&
        (shop.coupleImages.bride || shop.coupleImages.groom))
    ) {
      toast.error("Delete the images of the user first");
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this Restraunt",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReq();
      }
    });
  };

  const handleImageDelete = (imageUrl) => {
    const imageDeleteReq = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };

        const { data } = await axios.delete(
          `${baseUrl}/api/wedding/${currentShop.guid}/images`,
          {
            ...config,
            data: {
              guid: currentShop.guid,
              imageId: imageUrl,
            },
          }
        );

        if (data.success) {
          toast.success("Image deleted successfully");
          // Update local state to remove the deleted image URL
          fetchData();
          setCurrentShop((prevShop) => ({
            ...prevShop,
            gallery: prevShop.gallery.filter((url) => url !== imageUrl),
          }));
        }
        // console.log(data);
      } catch (err) {
        console.error("Error Deleting Image", err);
        toast.error("Error Occurred");
      }
    };
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this Image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        imageDeleteReq();
      }
    });
  };
  const handleCoupleImageDelete = (imageType) => {
    const imageDeleteReq = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };

        const { data } = await axios.delete(
          `${baseUrl}/api/wedding/${currentShop.guid}/coupleImage`,
          {
            ...config,
            data: {
              guid: currentShop.guid,
              imageType: imageType,
            },
          }
        );

        if (data.success) {
          toast.success("Image deleted successfully");
          // Update local state to remove the deleted image URL
          fetchData();
        }
        // console.log(data);
      } catch (err) {
        console.error("Error Deleting Image", err);
        toast.error("Error Occurred");
      }
    };
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this Image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        imageDeleteReq();
      }
    });
  };
  const downloadQRCode = (shop) => {
    const canvas = canvasRefs.current[shop.guid]?.querySelector("canvas");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${shop.guid}-qrcode.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      alert("QR code not available");
    }
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  if (user.role !== "admin") {
    return <h1>You are not authorized to access this page</h1>;
  }

  return (
    <div
      className={`flex h-screen bg-gray-200 w-full ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div
        className={`flex-1 flex flex-col ${
          isOpen ? "ml-0" : ""
        } overflow-y-auto`}
      >
        <Navbar
          toggleSidebar={toggleSidebar}
          isOpen={isOpen}
          toggleDarkMode={toggleDarkMode}
        />
        <div className="container mx-auto p-4">
          {isFormVisible ? (
            <div>
              <div className="p-4 mt-0 rounded">
                <textarea
                  value={(currentShop && currentShop.sectionContent) || ""}
                  onChange={(e) =>
                    handleHtmlChange(e.target.value, selectedUser)
                  }
                  className="mb-12 w-full h-64 p-2 border bg-vscode-dark text-white vscode"
                  style={{
                    height: "500px",
                    fontFamily:
                      "Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
                  }}
                />
                <button
                  onClick={handleSubmit}
                  className="bg-green-500 text-white px-4 py-2 rounded"
                >
                  Submit <FontAwesomeIcon icon={faCheck} />
                </button>
                <button
                  onClick={handleBackToTable}
                  className="bg-red-500 text-white px-4 py-2 rounded ml-2"
                >
                  Close <FontAwesomeIcon icon={faClose} />
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex justify-end mb-4">
                <button
                  className="bg-gray-800 text-white px-6 py-2 rounded-md flex items-center hover:bg-blue-600 transition"
                  onClick={() => openModal({})}
                >
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    size="sm"
                    className="mx-2"
                  />{" "}
                  Shop
                </button>
              </div>
              <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                <thead className="bg-gray-800 text-white">
                  <tr>
                    <th className="p-4">Bride</th>
                    <th className="p-4">Groom</th>
                    <th className="p-4">contactNumber</th>
                    <th className="p-4">Status</th>
                    <th className="p-4">Gallery Image Uploads</th>
                    <th className="p-4">Add Section</th>
                    <th className="p-4">Expires At</th>
                    <th className="p-4">Download QR</th>
                    <th className="p-4">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {shops.map((wed) => (
                    <tr
                      key={wed.id}
                      className="border-t border-gray-200 text-gray-700"
                    >
                      <td className="p-4">{wed.coupleNames.bride}</td>
                      <td className="p-4">{wed.coupleNames.groom}</td>
                      <td className="p-4">{wed.contactNumber}</td>
                      <td className="p-4">
                        <span
                          className={`px-2 py-1 rounded-full ${
                            wed.status === "active"
                              ? "bg-green-200 text-green-800"
                              : "bg-red-200 text-red-800"
                          }`}
                        >
                          {wed.status}
                        </span>
                      </td>
                      <td
                        className="p-4 text-center"
                        style={{ justifyContent: "center" }}
                      >
                        {`${wed.gallery.length}`}
                      </td>

                      <td className="p-4">
                      <FontAwesomeIcon
                        icon={faCirclePlus}
                        onClick={() => {
                          handleAddSection(wed, user.email, user.url);
                        }}
                        className="text-white-500 cursor-pointer text-2xl text-center"
                        title="Add Section" // Optional: Add a title for better accessibility
                      /> 
                      </td>
                      <td className="p-4">
                        {moment(wed.expireDate).format("DD/MM/YYYY")}
                      </td>
                      <td className="p-4" style={{ justifyContent: "center" }}>
                        <div ref={(el) => (canvasRefs.current[wed.guid] = el)}>
                          <button
                            className="text-black-500 hover:text-blue-700 transition text-center m-auto"
                            onClick={() => downloadQRCode(wed)}
                          >
                            <QRCode
                              value={`https://qrdcard.com/menu/${wed.guid}`}
                              size={512}
                              level={"H"}
                              includeMargin={true}
                              renderAs="canvas"
                              className="text-center m-auto"
                              style={{ display: "none" }}
                            />
                            <FontAwesomeIcon
                              icon={faQrcode}
                              size="lg"
                              className="m-auto"
                            />
                          </button>
                        </div>
                      </td>
                      <td className="p-4">
                        <button
                          className="text-gray-700 hover:text-blue-700 transition mr-2"
                          onClick={() => {
                            const url = `${window.location.origin}/menu/${wed.guid}`;
                            window.open(url, "_blank"); // Opens the URL in a new tab
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            size="md"
                            className="mx-1"
                          />
                        </button>
                        <button
                          className="text-yellow-500 hover:text-yellow-700 transition mr-2"
                          onClick={() => {
                            setIsEdit(true);
                            openModal(wed);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faEdit}
                            size="md"
                            className="mx-1"
                          />
                        </button>
                        <button
                          className="text-red-500 hover:text-red-700 transition"
                          onClick={() => {
                            handleDelete(wed);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            size="md"
                            className="mx-1"
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {isModalOpen && (
           <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 p-4">
            <div className="bg-white px-6 py-4 rounded-md shadow-md w-1/3 mt-14 mb-14 max-h-full overflow-auto scrollbar-hidden">
                <h2 className="text-2xl font-bold mb-2 text-gray-700">
                  {currentShop.guid ? "Edit Wedding" : "Add Wedding"}
                </h2>
                <div>
                  <div className="mb-3">
                    <label className="block text-gray-700">Bride Name</label>
                    <input
                      type="text"
                      name="brideName"
                      value={
                        (currentShop.coupleNames &&
                          currentShop.coupleNames.bride) ||
                        ""
                      }
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md bg-slate-50 text-gray-700"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block text-gray-700">Groom Name</label>
                    <input
                      type="text"
                      name="groomName"
                      value={
                        (currentShop.coupleNames &&
                          currentShop.coupleNames.groom) ||
                        ""
                      }
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md bg-slate-50 text-gray-700"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block text-gray-700">
                      Function Hall Name
                    </label>
                    <input
                      type="text"
                      name="functionHallName"
                      value={
                        (currentShop.address &&
                          currentShop.address.functionHallName) ||
                        ""
                      }
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md bg-slate-50 text-gray-700"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block text-gray-700">Place</label>
                    <input
                      type="text"
                      name="place"
                      value={
                        (currentShop.address && currentShop.address.place) || ""
                      }
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md bg-slate-50 text-gray-700"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block text-gray-700">City</label>
                    <input
                      type="text"
                      name="city"
                      value={
                        (currentShop.address && currentShop.address.city) || ""
                      }
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md bg-slate-50 text-gray-700"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block text-gray-700">State</label>
                    <input
                      type="text"
                      name="state"
                      value={
                        (currentShop.address && currentShop.address.state) || ""
                      }
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md bg-slate-50 text-gray-700"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block text-gray-700">Map URL</label>
                    <input
                      type="text"
                      name="mapURL"
                      value={currentShop.mapURL || ""}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md bg-slate-50 text-gray-700"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block text-gray-700">
                      Contact Number
                    </label>
                    <input
                      type="text"
                      name="contactNumber"
                      value={currentShop.contactNumber || ""}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md bg-slate-50 text-gray-700"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block text-gray-700">Landing Image</label>
                    <input
                      type="file"
                      name="landingImage"
                      multiple
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md bg-slate-50 text-gray-700"
                    />
                    {currentShop.landingImage && (
                      <div className="flex justify-between">
                        <div>
                          {
                            currentShop.landingImage
                              .split("/")
                              .slice(-2)
                              .join("/")
                              .split(".")[0]
                          }
                        </div>
                        <div>
                          {isEdit && (
                            <button
                              className="text-red-500 hover:text-red-700 transition"
                              onClick={() => {
                                handleImageDelete(currentShop.landingImage);
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} size="md" />
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="block text-gray-700">Bride Image</label>
                    <input
                      type="file"
                      name="brideImage"
                      multiple
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md bg-slate-50 text-gray-700"
                    />
                    {currentShop.coupleImages &&
                      currentShop.coupleImages.bride && (
                        <div className="flex justify-between">
                          <div>
                            {
                              currentShop.coupleImages.bride
                                .split("/")
                                .slice(-2)
                                .join("/")
                                .split(".")[0]
                            }
                          </div>
                          <div>
                            {isEdit && (
                              <button
                                className="text-red-500 hover:text-red-700 transition"
                                onClick={() => {
                                  handleCoupleImageDelete("bride");
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} size="md" />
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="mb-3">
                    <label className="block text-gray-700">Groom Image</label>
                    <input
                      type="file"
                      name="groomImage"
                      multiple
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md bg-slate-50 text-gray-700"
                    />
                    {currentShop.coupleImages &&
                      currentShop.coupleImages.groom && (
                        <div className="flex justify-between">
                          <div>
                            {
                              currentShop.coupleImages.groom
                                .split("/")
                                .slice(-2)
                                .join("/")
                                .split(".")[0]
                            }
                          </div>
                          <div>
                            {isEdit && (
                              <button
                                className="text-red-500 hover:text-red-700 transition"
                                onClick={() => {
                                  handleCoupleImageDelete("groom");
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} size="md" />
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="mb-3">
                  <label className="block text-gray-700">Gallery Uploads</label>
                  <input
                    type="file"
                    name="imageUrls"
                    multiple
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded-md bg-slate-50 text-gray-700"
                  />
                </div>

                {isUploading ? (
                  <div className="flex justify-center items-center">
                    <svg
                      className="animate-spin h-5 w-5 text-gray-700"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C6.477 0 2 4.477 2 10.5S6.477 21 12 21c1.285 0 2.505-.242 3.594-.674l-1.594-2.69A6.973 6.973 0 0112 18c-3.866 0-7-3.134-7-7z"
                      ></path>
                    </svg>
                  </div>
                ) : (
                  currentShop.gallery && (
                    <div className="image-list-container text-gray-700">
                      <ul>
                        {currentShop.gallery.map((image, index) => {
                          // Extract image name from URL
                          const imageName = image.split('/').pop().split('.').slice(0, -1).join('.');

                          return (
                            <li key={index} className="flex justify-between items-center py-2 border-b">
                              <div>
                                {index + 1}. {imageName}
                              </div>
                              {isEdit && (
                                <div>
                                  <button
                                    className="text-red-500 hover:text-red-700 transition"
                                    onClick={() => handleImageDelete(image)}
                                    aria-label={`Delete image ${index + 1}`}
                                  >
                                    <FontAwesomeIcon icon={faTrash} size="md" />
                                  </button>
                                </div>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )
                )}
                  <div className="mb-3">
                    <label className="block text-gray-700">Status</label>
                    <select
                      name="status"
                      value={currentShop.status || ""}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md bg-slate-50 text-gray-700"
                    >
                      <option value="active">Active</option>
                      <option value="inActive">Inactive</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="block text-gray-700 mb-1">
                      Marriage Date and Time
                    </label>
                    <input
                      type="datetime-local"
                      name="marriageDate"
                      value={
                        moment(currentShop.marriageDate).format(
                          "YYYY-MM-DDTHH:mm"
                        ) || ""
                      }
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md bg-slate-50 text-gray-700"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block text-gray-700 mb-1">
                      Lunch Date and Time
                    </label>
                    <input
                      type="datetime-local"
                      name="lunchDate"
                      value={
                        moment(currentShop.marriageDateTime).format(
                          "YYYY-MM-DDTHH:mm"
                        ) || ""
                      }
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md bg-slate-50 text-gray-700"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="block text-gray-700">Expires At</label>
                    <input
                      type="date"
                      name="expireDate"
                      value={
                        moment(currentShop.expireDate).format("YYYY-MM-DD") ||
                        ""
                      }
                      onChange={handleChange}
                      className="w-full px-3 py-2 border rounded-md bg-slate-50 text-gray-700"
                    />
                  </div>
                  <div className="mb-3 flex justify-center">
   
                  </div>
                  <div className="flex justify-end space-x-2">
                    <button
                      type="button"
                      onClick={closeModal}
                      className="bg-gray-300 text-gray-700 hover:bg-gray-400 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-blue-500 text-white hover:bg-blue-600 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ManageWedding;
