import React, { useState } from "react";
import * as XLSX from "xlsx";
import Sidebar from "./common/Sidebar";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import Navbar from "./common/Navbar";
import { ContentsState } from "../Context/ContentProvider";

const ManageImport = () => {
  const [data, setData] = useState([]);
  const [goodData, setGoodData] = useState([]);
  const [errorData, setErrorData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const { user } = ContentsState();
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const { isDarkMode, toggleDarkMode } = ContentsState();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      alert("Upload File");
    } else {
      const reader = new FileReader();

      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const workbook = XLSX.read(arrayBuffer, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        // Convert Excel serial date numbers to formatted dates
        const formattedData = jsonData.map((row) => {
          if (row.expireDate) {
            // Check if expireDate is a number (Excel date serial number)
            if (typeof row.expireDate === "number") {
              // Convert Excel serial date to JavaScript Date object
              const date = XLSX.SSF.parse_date_code(row.expireDate);
              row.expireDate = moment(
                new Date(date.y, date.m - 1, date.d)
              ).format("DD-MM-YYYY");
            }
          }
          return row;
        });

        setData(formattedData);
      };

      reader.readAsArrayBuffer(file);
    }
  };
  const handleValidSubmit = () => {
    const registerManyUsers = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
        const res = await axios.post(
          `${baseUrl}/api/user/registerImports`,
          {
            usersData: goodData,
          },
          config
        );
        console.log(res.data);
        toast.success("Data Sent");
      } catch (err) {
        toast.error(err.response?.data?.message || "Error occurred");
      }
    };
    if (!goodData) {
      toast.error("No Valid Data to upload");
      return;
    }
    registerManyUsers();
  };
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    const registerUser = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
        const res = await axios.post(
          `${baseUrl}/api/user/importusers`,
          {
            jsonData: data,
          },
          config
        );

        console.log(res.data);
        setGoodData(res.data.goodData);
        setErrorData(res.data.errorData);
        toast.success("Data Sent");
      } catch (err) {
        console.log(err);
        toast.error(err.response?.data?.message || "Error occurred");
      }
    };

    if (!data) {
      toast.error("Please Upload a file");
      return;
    }

    registerUser();
  };


  if (user.role !== "admin") {
    return <h1>You are not authorized to access this page</h1>;
  }

  return (
    <div className={`flex h-screen bg-gray-200 w-full ${isDarkMode ? "dark-mode" : ""}`}>
    <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
    <div
      className={`flex-1 flex flex-col ${
        isOpen ? "ml-0" : ""
      } overflow-y-auto`}
    >
      <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen} toggleDarkMode={toggleDarkMode}  />
      <div className="flex-1 p-6 overflow-auto">
        <div className="w-full mx-auto bg-white shadow-lg rounded-lg p-6">
          <h2 className="text-2xl font-bold mb-6 text-center text-gray-700">
            Upload Excel File
          </h2>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            className="block w-full mb-6 p-4 border border-gray-300 rounded-lg shadow-sm text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />

          <div className="mt-6 text-center">
            <button
              onClick={handleSubmit}
              className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-75"
            >
              Submit Data
            </button>
          </div>

          {goodData.length > 0 && (
            <div className="mt-6">
              <h3 className="text-xl font-semibold mb-4 text-center text-green-600">
                Valid Entries
              </h3>
              <div className="overflow-auto">
                <table className="min-w-full bg-white border border-gray-300 rounded-lg">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        Username
                      </th>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        Email
                      </th>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        Phone Number
                      </th>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        Status
                      </th>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        Role
                      </th>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        Business Name
                      </th>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        Expire Date
                      </th>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        Password
                      </th>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        URL
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {goodData.map((row, index) => (
                      <tr key={index} className="text-gray-700">
                        <td className="py-3 px-4 border-b">{row.username}</td>
                        <td className="py-3 px-4 border-b">{row.email}</td>
                        <td className="py-3 px-4 border-b">
                          {row.phoneNumber}
                        </td>
                        <td className="py-3 px-4 border-b">{row.status}</td>
                        <td className="py-3 px-4 border-b">{row.role}</td>
                        <td className="py-3 px-4 border-b">
                          {row.companyName}
                        </td>
                        <td className="py-3 px-4 border-b">{row.expireDate}</td>
                        <td className="py-3 px-4 border-b">{row.password}</td>
                        <td className="py-3 px-4 border-b">{row.url}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mt-6 text-center">
                <button
                  onClick={handleValidSubmit}
                  className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-75"
                >
                  Submit Valid Data
                </button>
              </div>
            </div>
          )}
          {errorData.length > 0 && (
            <div className="mt-6">
              <h3 className="text-xl font-semibold mb-4 text-center text-red-600">
                Invalid Entries
              </h3>
              <div className="overflow-auto">
                <table className="min-w-full bg-white border border-gray-300 rounded-lg">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        Username
                      </th>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        Email
                      </th>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        Phone Number
                      </th>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        Status
                      </th>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        Role
                      </th>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        Business Name
                      </th>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        Expire Date
                      </th>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        Password
                      </th>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        URL
                      </th>
                      <th className="py-3 px-4 border-b text-left text-gray-700">
                        Error Message
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {errorData.map((row, index) => (
                      <tr key={index} className="text-gray-700">
                        <td className="py-3 px-4 border-b">{row.username}</td>
                        <td className="py-3 px-4 border-b">{row.email}</td>
                        <td className="py-3 px-4 border-b">
                          {row.phoneNumber}
                        </td>
                        <td className="py-3 px-4 border-b">{row.status}</td>
                        <td className="py-3 px-4 border-b">{row.role}</td>
                        <td className="py-3 px-4 border-b">
                          {row.companyName}
                        </td>
                        <td className="py-3 px-4 border-b">{row.expireDate}</td>
                        <td className="py-3 px-4 border-b">{row.password}</td>
                        <td className="py-3 px-4 border-b">{row.url}</td>
                        <td className="py-3 px-4 border-b text-red-600">
                          {row.message}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
    </div>
  );
};

export default ManageImport;
